<template>
  <div class="download">
    <div class="downloadBox">
      <div class="downloadTitle">
        <p>
          Smart模块是长期迭代升级的工具产品系列，不仅是模块硬件不定期更新和发布新品，软件固件同样是会有更新升级，以增加新功能，
          和对功能错误进行优化修复。
        </p>
        <p>通常在使用Smart模块前，需要检查固件是否为最新版，我们建议直接使用“互动工具箱”中的
          <span @click="handleSet">恢复出厂设置</span> 功能，个别模块的固件需要在本页面下载，手动更新。</p>
      </div>
      <div class="downloadArea">
        <div class="downBox">
          <div class="downTitle">模块型号</div>
          <div class="downList config">
            <ul>
              <li v-for="(item, index) in configList"
                :key="index"
                @click="handleList(item)"
              >
                {{ item }}
              </li>
            </ul>
          </div>
        </div>
        <div class="downBox">
          <div class="downTitle">固件下载</div>
          <div class="downList information">
            <ul>
              <li v-for="(item, index) in informationList" :key="index">
                <span class="text" :title="item.key">{{ item.key.split("/")[1] + item.key.split("/")[2] }}</span>
                <span class="down" @click="handledownLoad(item.key)" v-if="index!=0">下载</span>
              </li>
            </ul>
          </div>
        </div>
        <div class="downBox">
          <div class="downTitle">沉浸式互动工具箱</div>
          <div class="downList information toolBox">
            <ul>
              <li v-for="(item, index) in toolList" :key="index">
                <span class="text" :title="item.key">{{ item.key.split("/")[1] }}</span>
                <span class="down" @click="downLoadTool(item.key)">下载</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {fileFolder, listFile, toolBox} from '@/api/download';
export default {
  data() {
    return {
      configList: [],
      informationList: [],
      toolList: []
    };
  },
  created() {
    this.selectConfigList();
    this.selectToolBox();
  },
  methods: {
    selectConfigList() {
      fileFolder().then(res => {
        this.configList = res.data.fileFolder;
        this.handleList(this.configList[0]);
      }).catch(err => {
        console.log(err);
      });
    },
    selectToolBox() {
      toolBox().then(res => {
        this.toolList = res.data.listFile.objectSummaries.filter((item, index) => {
          return index != 0;
        });
      }).catch(err => {
        console.log(err);
      });
    },
    handleList(name) {
      listFile(name).then(res => {
        this.informationList = res.data.listFile.objectSummaries;
      }).catch(err => {
        console.log(err);
      });
    },
    handledownLoad(name) {
      let localName = name.split("/")[2];
      let serve = process.env.NODE_ENV === 'production' ? process.env.VUE_APP_BASE_API : process.env.VUE_APP_ENV_URL;
      window.location.href = serve + '/mall/api/oss/downloadFile?objectName=' + name + '&localFileName=' + localName;
    },
    downLoadTool(name) {
      let localName = name.split("/")[1];
      let serve = process.env.NODE_ENV === 'production' ? process.env.VUE_APP_BASE_API : process.env.VUE_APP_ENV_URL;
      window.location.href = serve + '/mall/api/oss/downloadFile?objectName=' + name + '&localFileName=' + localName;
    },
    handleSet() {
      window.open('https://book.yxyc.top/@smart_mk/mokuaichushihuayubanbengengxin.html');
    }
  }
};
</script>

<style lang="less" scoped>
.download {
  width: 100%;
  height: 100%;
  min-width: 1200px;
  overflow: hidden;
  .downloadBox {
    padding: 0 10px;
    .downloadTitle {
      font-size: 20px;
      line-height: 35px;
      font-weight: 500;
      span {
        color: #3aaee2;
        cursor: pointer;
      }
    }
    .downloadArea {
      display: flex;
      justify-content: space-between;
      margin: 70px 0;
      .downBox {
        width: 32%;
        .downTitle {
          font-size: 15px;
          font-weight: 500;
          padding: 5px 10px;
          border-bottom: 1px solid #dddddd;
        }
        .downList {
          li {
            padding: 5px 10px;
            font-size: 17px;
            color: #31aee5;
            background: #f5f5f5;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          li:not(:last-child) {
            border-bottom: 1px solid #dddddd;
          }
        }
        .config {
          li {
            cursor: pointer;
          }
        }
        .information {
          li {
            display: flex;
            justify-content: space-between;
            color: black;
            padding-right: 30px;
            .text {
              max-width: 90%;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
            .down {
              color: #31aee5;
            }
          }
          li:not(:first-child) {
            cursor: pointer;
          }
        }
        .toolBox {
          li {
            cursor: pointer;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 900px){
  .downloadArea {
    flex-wrap: wrap !important;
    .downBox {
      width: 100% !important;
    }
  }
}
</style>