<template>
  <div id="app">

      <Header v-if="showHeader"></Header>
    
    <router-view/>
    <Footer v-if="showHeader"></Footer>
    
  </div>
</template>

<script>
import Header from "./components/Header.vue";
import Footer from "./components/Footer.vue";
export default {
  components:{Header,Footer},
  data() {
    return {
      showHeader: true
    };
  },
  updated() {
    if(this.$route.name == 'DownloadImmerse'){
      this.showHeader = false
    }else{
      this.showHeader = true
    }
  }
};
</script>

<style lang="less" scoped>
#app{
  width: 100%;
  min-width: 1200px;
}
</style>
