<template>
  <div class="solve mgat">
    <h2 class="ft56">您的痛点，我们都能解决</h2>
    <div>
      <div class="solve-content">
        <div
          class="colve-container"
          v-for="(item, index) in solve"
          :key="index"
        >
          <div class="solve-title">{{ item.title }}</div>
          <div class="solve-text">{{ item.text }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    solve: Array,
  },
};
</script>

<style lang="less" scoped>
.solve {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 90%;
  // min-width: 1200px;
  max-width: 1300px;
  h2 {
    margin: 80px 0;
  }
  .solve-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    .colve-container {
      width: 45%;
      height: 200px;
      border-radius: 20px;
      background: rgba(255, 255, 255, 1);
      box-shadow: 0px 2px 8px 0px rgba(3, 24, 56, 0.3);
      padding: 20px;
      margin-bottom: 30px;
      
      .solve-title {
        text-indent: -0.5em;
        font-size: 24px;
        font-weight: bold;
        margin-bottom: 20px;

      }
      .solve-text {
        font-size: 20px;
        color: #7d7d7d;
        text-indent: 0;
      }
    }

    .colve-container:nth-child(odd) {
      margin-right: 20px;
    }
  }
}
</style>