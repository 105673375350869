<template>
  <div class="header">
    <div class="logo">
      <img src="../assets/images/Realistic/logo.png" alt="" />
    </div>
    <ul class="HandNav">
      <li v-for="(item, index) in nav" :key="index" @click="Handel(index)" :class="{ activeColor: actv == index }">
        <p :class="{ activation: actv == index }"></p>
        {{ item }}
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  data() {
    return {
      nav: ["核心工具产品", "实景业务", "展厅业务", "互动美陈业务", "其他业务"],
      actv: null,
      nowPath: null,
    };
  },
  watch: {
    $route(val) {
      switch (val.path) {
        case "/CoreTools":
          this.actv = 0
          break;
        case "/Realistic":
          this.actv = 1
          break;
        case "/Exhibition":
          this.actv = 2
          break;
        case "/DisplayBusiness":
          this.actv = 3
          break;
        case "/PageLayout":
          this.actv = 4
          break;
      }
    },
  },

  methods: {
    Handel(index) {
      if (index == 0) this.$router.push("/CoreTools");
      if (index == 1) this.$router.push("/Realistic");
      if (index == 2) this.$router.push("/Exhibition");
      if (index == 3) this.$router.push("/DisplayBusiness");
      if (index == 4) this.$router.push("/PageLayout");
    },
  },
};
</script>

<style lang="less" scoped>
.header {
  width: 100%;
  min-width: 1200px;
  height: 90px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  background: #fafafa;
  .logo {
    img {
      height: 56px;
      width: 120px;
    }
  }
  .HandNav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    li {
      margin-right: 20px;
      cursor: pointer;
      font-size: 18px;
      position: relative;
    }
    .activation {
      height: 60px;
      width: 100%;
      border: 5px solid transparent;
      border-bottom-color: #006aff;
      position: absolute;
      bottom: -31px;
    }
    .activeColor {
      color: #006aff;
    }
  }
}
</style>
