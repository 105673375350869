<template>
  <div class="CpreTools">
    <div class="banner colorfff" >
      <div class="banner-content">
        <div class="ft72 ftw color006">拼装，让您的生活更有趣</div>
        <div class="ft80 ftw banner-title">核心工具产品</div>
        <div class="banner-text ft32 color229">
          <div>Smart模块定位于帮助用户快速搭建</div>
          <div>互动场景的控制系统，是一款智能的，互联互动的，互动场</div>
          <div>景搭建工具产品</div>
        </div>
        <div class="banner-list ft20 color229">
          <div>工具产品</div>
          <div>完善体系</div>
          <div>标准量产</div>
          <div>行业沉淀</div>
          <div>模块优势</div>
          <div>共建生态</div>
        </div>
      </div>
    </div>
    <div class="suspension"></div>
    <div class="suspension1"></div>
    <div class="suspension2"></div>
    <div class="suspension3"></div>
    <div class="core-list" >
      <div class="core-container mgat" @mouseover="coreMask6=true" @mouseleave="coreMask6=false">
        <img src="../assets/images/CoreTools/工具产品.jpg" alt="" />
        <div class="colorfff" v-show="!coreMask6">工具产品</div>
        <div class="mask" v-show="coreMask6">
          <div class="mask-container" >
              <div>工具产品</div>
              <div>Smart模块定位于帮助用户快速搭建互动场景的控制系统，是一款智能的，互联互动的，互动场景搭建工具产品。</div>
          </div>
        </div>
      </div>
      <div class="core-container mgat" @mouseover="coreMask1=true" @mouseleave="coreMask1=false">
        <img src="../assets/images/CoreTools/完善体系.jpg" alt="" />
        <div class="colorfff" v-show="!coreMask1">完善体系</div>
        <div class="mask" v-show="coreMask1">
          <div class="mask-container" >
              <div>完善体系</div>
              <div>硬件产品，专业软件，以及开放平台，共同构建起Smart模块生态的基础，为用户提供专业稳定齐全的互动场景搭建工具。</div>
          </div>
        </div>
      </div>
      <div class="core-container mgat" @mouseover="coreMask2=true" @mouseleave="coreMask2=false">
        <img src="../assets/images/CoreTools/标准量产.jpg" alt="" />
        <div class="colorfff" v-show="!coreMask2">标准量产</div>
        <div class="mask" v-show="coreMask2">
          <div class="mask-container" >
              <div>标准量产</div>
              <div>模块具有全部互相兼容的标准接口，在深圳，潍坊，菏泽建有生产基地，标准化量产，标准化老化试验，保证供货稳定且100%出货良品率</div>
          </div>
        </div>
      </div>
      <div class="core-container mgat" @mouseover="coreMask3=true" @mouseleave="coreMask3=false">
        <img src="../assets/images/CoreTools/行业沉淀.jpg" alt="" />
        <div class="colorfff" v-show="!coreMask3">行业沉淀</div>
        <div class="mask" v-show="coreMask3">
          <div class="mask-container" >
              <div>行业沉淀</div>
              <div>应用于沉浸式体验，密室逃脱，剧本杀，展厅，商业展陈，活动道具等多种互动场景的控制方案搭建。为5000+用户提供上万种互动控制方案。</div>
          </div>
        </div>
      </div>
      <div class="core-container mgat" @mouseover="coreMask4=true" @mouseleave="coreMask4=false">
        <img src="../assets/images/CoreTools/模块优势.jpg" alt="" />
        <div class="colorfff" v-show="!coreMask4">模块优势</div>
        <div class="mask" v-show="coreMask4">
          <div class="mask-container" >
              <div>模块优势</div>
              <div>相比传统的定制系统，模块化的工具型产品，具有可DIY，快速落地，重复使用，高效运维等特点。</div>
          </div>
        </div>
      </div>
      <div class="core-container mgat" @mouseover="coreMask5=true" @mouseleave="coreMask5=false">
        <img src="../assets/images/CoreTools/共建生态.jpg" alt="" />
        <div class="colorfff" v-show="!coreMask5">共建生态</div>
        <div class="mask" v-show="coreMask5">
          <div class="mask-container" >
              <div>共建生态</div>
              <div>意想意创与用户共同维护构建的Smart模块生态，自2015年发布以来经历了多次重大更新迭代。</div>
             
          </div>
        </div>
      </div>
    </div>

    <div class="smart">
      <div class="smart-title ft56">Smart模块硬件产品</div>
      <div class="smart-introduce ft20 color128">
        <div>
          丰富的硬件产品线，包括功能主控，传感器模块，配件模块，概念系列等，二百余款模块。
        </div>
        <div>
          无论是面向创意者，还是面向生产者，都可以为互动场景控制方案提供稳定的高品质量产产品。
        </div>
        <div>
          模块之间全部采用标准接口，互相兼容，即使迭代更新也不影响模块兼容性。
        </div>
      </div>
      <div class="smart-nav">
        <div :class="{active:active==0}" @click="active=0">功能主控</div>
        <div :class="{active:active==1}" @click="active=1"> X概念系列</div>
        <div :class="{active:active==2}" @click="active=2">传感器模块</div>
      </div>
      <div class="smart-content "  v-show="active==0">
        <div class="smart-content-text">
          <div class="smart-content-text-title">功能主控</div>
          <div class="smart-content-text-list">
            <div>
              通常具有多个输入和输出口，<span class="color006"
                >在控制系统中承载功能和逻辑</span
              >，采集输入的信号，按照设定的功能或逻辑，控制输出动作。
            </div>
            <div>
              主控模块可运行图形化配置系统，通过拖拽，快速自由配置自己想要的功能和逻辑。
            </div>
          </div>
        </div>
        <div class="smart-content-img">
          <img src="../assets/images/CoreTools/灯锁主控2的副本.jpg" alt="" />
        </div>
      </div>
      <div class="smart-content "  v-show="active==1">
       
        <el-carousel trigger="click" height="660px">
          <el-carousel-item >
        <div class="smart-content smart_swiper">
          <div class="smart-content-text">
          <div class="smart-content-text-title">Smart X3系列模块</div>
          <div class="smart-content-text-list">
            <div>
              X概念系列的第三代产品，通过X3系列模块的组合，可以形成一套可以接入互联网的，具有大量标准输入输出接口的，并可以通过微信小程序进行管理运维的控制方案。
            </div>
          </div>
        </div>
        <div class="smart-content-img">
          <img  style="max-width:425px" src="../assets/images/CoreTools/X3模块2.jpg" alt="" />
        </div>
        <div class="smart_container">
          <span>X概念系列</span>是为行业推出的次世代工具产品，具有功能主控难以达成的各种新功能，新方案，更加丰富了创意者和生产者的互动场景控制方案。
        </div>
        </div>
      </el-carousel-item>
      <!-- 1111 -->
          <el-carousel-item >
        <div class="smart-content smart_swiper">
          <div class="smart-content-text">
          <div class="smart-content-text-title">YiNet</div>
          <div class="smart-content-text-list">
            <div>
              是一种基于总线的大规模控制方案，控制器挂载于总线的布线方式，可以让布线安装工作，调试工作大幅简化。
            </div>
          </div>
        </div>
        <div class="smart-content-img">
          <img style="max-width:425px" src="../assets/images/CoreTools/yinet.jpg" alt="" />
        </div>
        <div class="smart_container">
          <span>X概念系列</span>是为行业推出的次世代工具产品，具有功能主控难以达成的各种新功能，新方案，更加丰富了创意者和生产者的互动场景控制方案。
        </div>
        </div>
      </el-carousel-item>
    </el-carousel>
      </div>
      <div class="smart-content "  v-show="active==2">
        <div class="smart-content-text">
          <div class="smart-content-text-title">传感器模块</div>
          <div class="smart-content-text-list">
            <div>
                传感器作为控制器采集环境或体验者动作的“眼睛”，对于丰富互动玩法，产生多样体验至关重要。我们根据行业用户需求，自研自产数十款专业的传感器模块，模块化的设计，具有标准接口，可快速接入Smart主控。部分智能传感器模块还具有阈值判定，时间判定，设定密码逻辑等功能，进一步丰富了控制器“看”的方法。
            </div>
          </div>
        </div>

        <div class="smart-content-img">
          <img style="max-width:425px;object-fit: cover; width: 425px;" src="../assets/images/CoreTools/11.png" alt="" />
        </div>

        
      </div>
    </div>

    <!-- smart模块专业软件 -->
    <div class="smart-module">
        <div class="ft56 smart-module-title">Smart模块专业软件</div>
        <div class="smart-module-content" @mouseover="SmartMask=true" @mouseleave="SmartMask=false">
            <img src="../assets/images/CoreTools/smart模块.png" alt="">
            <div class="mask" v-show="SmartMask">
              <div>
                <div> 简称“图形化”，是Smart模块体系中，用于编辑主控模块运行逻辑的软件系统（可用于更改主控模块的功能）。</div>
              <div>主要由“功能块”和“行”组成，通过拖拽功能块到行内，进行顺序组合，可以实现编辑逻辑。    </div>
              <div>不同于图形化编程，图形化配置更侧重于逻辑的配置，而不是数据的处理，从而降低了用户应用门槛，使Smart功能主控模块，可以真正为用户所掌控。</div>
              </div>
            </div>
        </div>
        <div class="smart-module-container">
            <div class="smart-module-container-list">
                <div class="smart-module-container-list-left">免</div>
                <div class="smart-module-container-list-right">
                    <div class="smart-module-container-list-right-title">免定制•自由配</div>
                    <div class="smart-module-container-list-right-text">无需定制，自己拖拽功能块即可完成需求</div>
                </div>
            </div>
            <div class="smart-module-container-list">
                <div class="smart-module-container-list-left">零</div>
                <div class="smart-module-container-list-right">
                    <div class="smart-module-container-list-right-title">零代码•上手快</div>
                    <div class="smart-module-container-list-right-text">无需编程，完全零代码，2小时快速上手</div>
                </div>
            </div>
            <div class="smart-module-container-list">
                <div class="smart-module-container-list-left">学</div>
                <div class="smart-module-container-list-right">
                    <div class="smart-module-container-list-right-title">学习群•定期课</div>
                    <div class="smart-module-container-list-right-text">学习交流群，问题随时交流</div>
                </div>
            </div>
            <div class="smart-module-container-list">
                <div class="smart-module-container-list-left">量</div>
                <div class="smart-module-container-list-right" >
                    <div class="smart-module-container-list-right-title"> 可量产•迭代稳 </div>
                    <div class="smart-module-container-list-right-text">保存配置可用于量产，随时根据反馈快速优化迭代</div>
                </div>
            </div>
        </div>
    </div>
    <!-- smart开放平台 -->
    <div class="smartapi">
      <div class="ft56 smartapi_title">Smart开放平台</div>
      <div class="smartapi-introduce ft20">
        <div>Smart模块是一个开放的工具型产品体系，不仅模块的设计研发迭代是所有用户共同推进，还面向开</div>
        <div>发者开放了丰富的API和SDK。同时我们还与全球领先的零代码开发平台iVX达成合作，共同推进</div>
        <div>Smart开放平台在更多行业扎根生芽，大放光彩。</div>
        <div>Smart开放平台，提供有丰富的文档材料，及社区支持，可以辅助开发者快速接入，稳定落地项目。</div>
      </div>
      <div class="smartapi-container">
        <div class="smartapi-nav">
        <div :class="{activecolor:activecolor==0}" @click="activecolor=0"><p :class="{activecolorP:activecolor==0}" style="transform: skewX(0);">API</p></div>
        <div :class="{activecolor:activecolor==1}" @click="activecolor=1"><p :class="{activecolorP:activecolor==1}">X3 API</p></div>
        <div :class="{activecolor:activecolor==2}" @click="activecolor=2"><p :class="{activecolorP:activecolor==2}">X3控台API</p></div>
        <div :class="{activecolor:activecolor==3}" @click="activecolor=3"><p :class="{activecolorP:activecolor==3}">SmartAPI</p></div>
        <div :class="{activecolor:activecolor==4}" @click="activecolor=4"><p :class="{activecolorP:activecolor==4}">ivx</p></div>
      </div>
      <!-- 选项卡 -->
      <div class="smartapi-card" v-show="activecolor==0">
          <div class="smartapi-card-img">
            <img src="../assets/images/CoreTools/api.jpg" alt="">
          </div>
          <div class="smartapi-card-content">API即编程接口，我们为行业提供了专业稳定量产的Smart模块控制器，并面向开发者提供模块硬件的API。<br/>开发者可以通过API，使自己的程序可以获取传感器模块的数据，还可以控制各种灯光电机设备，让程序不仅仅是计算机中的代码，而是可以与环境，与人，与现实中的各种事物产生互动的核心。</div>
      </div>
      <div class="smartapi-card" v-show="activecolor==1">
          <div class="smartapi-card-img">
            <img src="../assets/images/CoreTools/x3api.jpg" alt="">
          </div>
          <div class="smartapi-card-content">开发者可以通过X3 API，控制由Smart X3组成的中央管理控制系统，可以实现小程序上的全部管理控制功能。<br/>开发者可以将专业稳定量产的Smart X3模块，作为现有中央管理控制系统的下位机，实现接入丰富的Smart模块硬件产品。</div>
      </div>
      <div class="smartapi-card" v-show="activecolor==2">
          <div class="smartapi-card-img">
            <img src="../assets/images/CoreTools/x3控台api.jpg" alt="">
          </div>
          <div class="smartapi-card-content">X3控台协议，是指Smart X3离线控台所应用的API。Smart X3不仅可以接入互联网运行，还可以离线运行，此时的中央管理控制系统就需要依靠控台或控台API的控制。依托Smart X3模块硬件及其配套丰富的周边模块产品，控台API可以实现丰富的中控系统方案。</div>
      </div>
      <div class="smartapi-card" v-show="activecolor==3">
          <div class="smartapi-card-img">
            <img src="../assets/images/CoreTools/smartapi.jpg" alt="">
          </div>
          <div class="smartapi-card-content">上位机可以通过Smart API，接入Smart功能主控，可以以极低的成本，让开发者的程序拥有获取传感器模块数据及控制灯光电机设备的能力。</div>
      </div>
      <div class="smartapi-card" v-show="activecolor==4">
          <div class="smartapi-card-img">
            <img src="../assets/images/CoreTools/ivx.png" alt="">
          </div>
          <div class="smartapi-card-content">iVX是领先全球的零代码通用开发平台，其“无视语法，保留逻辑”的理念，正在让“人人编程”成为现实。iVX作为通用开发平台，不仅可以制作桌面端应用（Windows/macOS/Linux），还可以制作网页端应用（兼容主流浏览器），移动端应用（iOS/Android），以及小程序（微信/阿里系/抖音）。零代码开发是指通过拖拽+配置进行开发，无需敲代码，大大提高了开发和运维效率。iVX中已经集成了X3 API和Smart API，并提供有例程，开发者可以快速完成开发调试。</div>
      </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
    data(){
        return{
            active:0,
            activecolor:0,
            SmartMask:false,
            coreMask6:false,
            coreMask1:false,
            coreMask2:false,
            coreMask3:false,
            coreMask4:false,
            coreMask5:false,
        }
    },
  
};
</script>

<style lang="less" scoped>
.CpreTools {
  width: 100%;
  min-width: 1200px;
  position: relative;
overflow: hidden;
  .suspension{

    position: absolute;
    left: -566.49px;
    top: 1567.93px;
    width: 100%;
    height: 1293px;
    border-radius: 50%;
    z-index: -1;
  }
  .suspension1{
    position: absolute;
    z-index: -1;
    left: 1324.27px;
top: 3244.59px;
width: 554.12px;
height: 538.7px;
opacity: 0.2;
transform: rotate(-306.50576206897904deg);
background: linear-gradient(90deg, rgba(72, 124, 234, 1) 0%, rgba(255, 255, 255, 1) 100%);
filter: blur(100px);
  }
  .suspension2{
    position: absolute;
    z-index: -1;
    left: 1123.51px;
top: 3867.93px;
width: 1330px;
height: 1293px;
opacity: 0.2;
transform: rotate(-197.4841550202261deg);
background: linear-gradient(90deg, rgba(72, 124, 234, 1) 0%, rgba(255, 255, 255, 1) 100%);
filter: blur(100px);

  }
  .suspension4{
    position: absolute;
    z-index: -1;
    left: 1123.51px;
top: 3867.93px;
width: 1330px;
height: 1293px;
opacity: 0.2;
transform: rotate(-197.4841550202261deg);
background: linear-gradient(90deg, rgba(72, 124, 234, 1) 0%, rgba(255, 255, 255, 1) 100%);
filter: blur(100px);

  }
  .banner {
    background: url(../assets/images/CoreTools/banner.jpg)  no-repeat center;
    background-size: cover;
    width: 100%;
    height: 984px;
    position: relative;
    margin-bottom: 50px;
    .banner-content {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      .banner-title {
        margin-top: 10px;
        margin-bottom: 29px;
      }
      .banner-text {
        margin-bottom: 160px;
        div {
          line-height: 38px;
        }
      }
      .banner-list {
        display: flex;
        div {
          border: 1px solid #fff;
          line-height: 35px;
          width: 168px;
          border-radius: 23px;
          text-align: center;
          margin-right: 35px;
        }
        :last-child {
          margin: 0;
        }
      }
    }
  }
  .core-container:nth-child(odd){
      margin-right: 40px;
    }
  .core-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 80%;
    max-width: 1150px;
    margin: auto;
    // display: grid;
    // grid-template-columns: 1fr 1fr ;
    .core-container {
      position: relative;
      margin-bottom: 20px;
      height: 294px;
      width: 45%;
      max-width: 522px;
      // margin-left: 0;
      img {
        width: 100%;
        height: 100%;
        border-radius: 30px;
      }
      .colorfff {
        position: absolute;
        font-size: 20px;
        bottom: 20px;
        left: 20px;
      }
      .mask{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        border-radius: 30px;
        color: #fff;
        width: 100%;
        height: 100%;
        background: rgba(0, 57, 143, 0.8);
        font-size: 18px;
        .mask-container{
          width: 100%;
          text-align: left;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%,-50%);
          padding-bottom: 30px;
          max-width: 343px;
          :first-child{
            font-size: 30px;
            text-align: center;
            margin-bottom: 10px;
          }
        }
      }
    }
  }
  .smart {
    width: 90%;
    text-align: center;
    margin: auto;
    max-width: 1082px;
    
    .smart-title {
      margin-top: 100px;
      margin-bottom: 50px;
    }
    .smart-introduce {
      margin-bottom: 30px;
    }
    .smart-nav {
      display: flex;
      margin: auto;
      align-items: center;
      width: 80%;
      justify-content: space-between;
      margin-bottom: 20px;
      
      div {
        min-width: 160px;
        line-height: 40px;
        border: 1px solid #006aff;
        border-radius: 20px;
        color: #006aff;
        font-size: 18px;
        cursor: pointer;
      }
      .active {
        background: linear-gradient(to bottom left, #f5f5f5, #4a92f7);
        color: #fff;
        border: 0;
        position: relative;
      }
      .active::after {
        content: "";
        position: absolute;
        left: 43%;
        bottom: -19px;
        border: 10px solid transparent;
        border-top-color: #7caaeb;
      }
    }
    .smart_swiper{
      padding-bottom: 40px;
    }
    .smart-content {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 660px;
      background: linear-gradient(to top, #fff, #f5f5f5);
      border-radius: 30px;
      position: relative;
      box-shadow: 0px 10px 10px 1px rgba(30, 117, 247, 0.1);

      .smart-content-text {
        // margin-right: 20px;
        .smart-content-text-title {
          font-size: 36px;
          text-align: left;
          margin-bottom:20px;
        }
        .smart-content-text-list {
          width: 300px;
          text-indent: 2em;
          text-align: left;
          font-size: 18.9px;
          color: rgba(51, 51, 51, 1);
          :first-child{
            margin-bottom: 20px;
          }
        }
      }
      .smart-content-img {
        img {
          height: 400px;
          object-fit: cover;
          border-radius: 10px;
          box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);
          margin-left: 128px;
        }
      }

      .smart_container{
        position: absolute;
        bottom: 30px;
        font-size: 16px;
        padding: 18px;
        width: 80%;
        text-align: left;
        border: 1px solid rgba(5, 105, 255, 1);  
        border-radius: 15px;
        color: rgba(128, 128, 128, 1);
        span{
          color: rgba(5, 105, 255, 1);
        }
      }
      .el-carousel{
        width: 100%;
        
      }
    }
  }
  .smart-module{
    width: 90%;
    margin: auto;
    text-align: center;
    .smart-module-title{
        margin-top: 100px;
        margin-bottom: 50px;
    }
    .smart-module-content{
          position: relative;
          height: 662px;
          margin: auto;
          margin-bottom: 40px;
          max-width: 1172px;
      img{
         width: 100%;
          height: 100%;

          object-fit: cover;
          border-radius: 30px;
      }
      .mask{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        border-radius: 30px;
        color: #fff;
        width: 100%;
        height: 100%;
        background: rgba(0, 57, 143, 0.8);
        font-size: 25px;
        >div{
          width: 745px;
          text-align: left;
          text-indent: 2em;
          position: absolute;
          top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        line-height: 50px;
        }
      }
    }
    .smart-module-container{
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin: auto;
        max-width: 1178px;
        .smart-module-container-list{
            width: 48%;
        height: 110px;
        display: flex;
        background: #fff;
        border-radius: 20px;
        overflow: hidden;
        margin-bottom: 20px;
        box-shadow: 2px 2px 4px 2px #f5f5f5;
        
        .smart-module-container-list-left{
            font-size: 40px;
            line-height: 110px;
            height: 110px;
            width: 110px;
            background: #006aff;
            color:#fff;
        }
        .smart-module-container-list-right{
            display: flex;
            flex-direction: column;
            justify-content: center;
            margin-left: 20px;
            .smart-module-container-list-right-title{
                text-align: left;
                color: #006aff;
                font-size: 20px;
            }
            .smart-module-container-list-right-text{
                color: #7d7d7d;
                font-size: 16px;
            }
        }
        }
        .smart-module-container-list:nth-child(odd){
            margin-right: 20px;
        }
    }
  }
  .smartapi{
    width: 90%;
    margin: auto;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    .smartapi_title{
      margin-top: 100px;
      margin-bottom: 30px;
    }
    .smartapi-introduce{
      line-height: 25px;
      color: rgba(128, 128, 128, 1);
    }
    .smartapi-container{
      margin-top: 50px;
      max-width: 1209px;
      margin-bottom: 10px;
      .smartapi-nav{
        padding-left: 140px;
        display: flex;
        font-size: 18px;
        margin: auto;
        position: relative;
        div{
          width: 120px;
          height: 50px;
          background: rgb(226, 226, 226);
          line-height: 50px;
          position: relative;
          transform: skewX(15deg);
          cursor: pointer;
          p{
          transform: skewX(-15deg);
          color: #333;
          }
        }
        div:first-child{
          border-radius: 15px 0 0px;
          transform: rotateX(2deg);
          perspective-origin: left; 
          position: absolute;
          left: 26px;
        }
        .activecolor{
          background: #006aff;
          p{
          color: #fff;
          }
   
        }
        
   
        }

        .smartapi-card{
          width: 100%;
          height: 580px;
          border-radius: 20px;
          box-shadow: 2px 5px 5px 2px rgba(0, 0, 0, 0.25);
          
          display: flex;
          justify-content: center;
          align-items: center;
          .smartapi-card-img{
            width: 809px;
            margin:  0 40px;
            img{
              width: 809px;
              max-width: 100%;
              height: 400px;
              object-fit: cover;
               border-radius: 20px;
            // margin:  0 40px;
          }

          }
          .smartapi-card-content{
            font-size: 18px;
            flex: 1;
            min-width: 200px;
           margin-right: 45px;
            color: rgba(128, 128, 128, 1);
            text-align: left;
          }
        }
    }
  }
}
</style>