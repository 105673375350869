<template>
  <div class="business">

    
          <div class="suspension"></div>
        <div class="suspension1"></div>
        <div class="suspension2"></div>
    <div class="banner">
      <div class="banner-container colorfff">
        <div class="banner-content">
          设计 、服务、咨询、研发、方案、生产、加工
        </div>
        <div class="banner-title ft56 ftw">
          <div>为您提供<span class="color006">“一站式”</span></div>
          <div>产品及服务</div>
        </div>
        <div class="banner-text">
          <div>致力于解决客户 "复杂接线" "技术门槛</div>
          <div>高" "非功能性调试反复收费"的痛点</div>
        </div>
      </div>
    </div>

    <div class="advantage">
      <div class="advantage-title ft56">意想意创能给您带来更多优势</div>
      <div class="advantage-introduce">
        <div class="advantage-introduce-term">
          <div class="advantage-introduce-term-title">通电可用</div>
          <div class="advantage-introduce-term-img">
            <img src="../assets/images/DisplayBusiness/1.png" alt="" />
          </div>
          <div class="advantage-introduce-term-bg"></div>
          <div class="advantage-introduce-term-text">
            无需懂技术，通电可用。非功能性调式，工程师可免费调试三次！
          </div>
        </div>
        <div class="advantage-introduce-term">
          <div class="advantage-introduce-term-title">节省落地时间</div>
          <div class="advantage-introduce-term-img">
            <img src="../assets/images/DisplayBusiness/2.png" alt="" />
          </div>
          <div class="advantage-introduce-term-bg1"></div>
          <div class="advantage-introduce-term-text">
            触发传感器及效果端可以随意替换，逻辑助力美陈市场翻台快的现状。
          </div>
        </div>
        <div class="advantage-introduce-term">
          <div class="advantage-introduce-term-title">可反复利用</div>
          <div class="advantage-introduce-term-img">
            <img src="../assets/images/DisplayBusiness/3.png" alt="" />
          </div>
          <div class="advantage-introduce-term-bg2"></div>
          <div class="advantage-introduce-term-text">
            再也不囤货单片机！功能单一，不能改，风险大！图形化简单一调，瞬间换新功能，“来年还卖他！”
          </div>
        </div>
      </div>
      <div class="advantage-container">
        <div class="advantage-container-content">
          <div class="content-img" style="height:383px ;width: 566px;">
            <img style="height:383px" src="../assets/images/DisplayBusiness/呼吸之光2.jpg" alt="" />
          </div>
          <div class="content">
            <div class="title">美陈单品<span class="color006" style="font-weight: bold; font-size: 44px;">>></span></div>
            <div class="list">
              <div class="list-title"><img src="../assets/images/DisplayBusiness/圆圈1.png" style="height:16px;width: 16px;"> 亮点：</div>
              <div class="list-text">
                快速落地，通电可用；互动款的美陈多年不过时，单品市场成熟稳定，增加客户粘度和停留时间。
              </div>
            </div>
            <div class="list">
              <div class="list-title"><img src="../assets/images/DisplayBusiness/圆圈1.png" style="height:16px;width: 16px;">  解决痛点：</div>
              <div class="list-text">
                网红创意互动，发货快，不知道方案，无法落地。
              </div>
            </div>
          </div>
          
        </div>
        <div class="advantage-container-content">
          
          <div class="content">
            <div class="title">美陈控制器<span class="color006" style="font-weight: bold;font-size: 44px;">>></span></div>
            <div class="list">
              <div class="list-title"><img src="../assets/images/DisplayBusiness/圆圈1.png" style="height:16px;width: 16px;">  亮点：</div>
              <div class="list-text">
                自有仓库，最快当天发货，自有研发团队，您接单，我发货，我售后；小白门槛控制器；技术响应快。
              </div>
            </div>
            <div class="list">
              <div class="list-title"><img src="../assets/images/DisplayBusiness/圆圈1.png" style="height:16px;width: 16px;">  解决痛点：</div>
              <div class="list-text">
                第三方不懂技术，回复时效慢，失去项目竞标机会，单单要定制，效果不能改，能改又要收费。
              </div>
            </div>
            <div class="list">
              <div class="list-title"><img src="../assets/images/DisplayBusiness/圆圈1.png" style="height:16px;width: 16px;">  我们的方案：</div>
              <div class="list-text">
                一对一工程师服务，技术支撑，你只管接项目，我们来做技术响应，让我们“团战”出击！
              </div>
            </div>
          </div>

          <div class="content-img">
            <img src="../assets/images/DisplayBusiness/IMG_20191114_142647.jpg" alt="" />
          </div>
          
        </div>
      </div>
    </div>

    <div class="programme">
        <div class="ft56 programme-title"> 意想意创给您制定最适合您的方案</div>
        <div class="programme-container">
            <div class="container-list">
                <div class="list_title">技术：</div>
                <div>我们有研发团队</div>
                <div>软硬件的配合让你手中视频资源的互动效果不再遥远</div>
            </div>
            <div class="container-list">
                <div class="list_title">价格：</div>
                <div>我们可以根据您的预算</div>
                <div>为您定制适合自己项目的方案</div>
            </div>
        </div>
        <div class="programme_img">
                <div class="programme_img_container" @mouseover="mask =false" @mouseleave="mask = true">
                    <img src="../assets/images/DisplayBusiness/湖北景区公园项目.jpg" alt="">
                    <div class="programme_img_text" v-show="mask">湖北景区公园项目</div>
                    <div class="programme_img_text1"  v-show="!mask"> 
                        <div>
                            <div>原方案没有互动</div>
                        <div>解决了客流和对老旧景区停留的时间</div>
                        <div>改造景区为户动景区</div>
                        <div>增加人数</div>
                        </div>
                    </div>
                </div>
                <div  class="programme_img_container" @mouseover="mask1 =false" @mouseleave="mask1 = true">
                    <img src="../assets/images/DisplayBusiness/魔法屋.jpg" alt="">
                    <div  class="programme_img_text" v-show="mask1">多媒体互动幼儿园音乐教室项目</div>
                    <div class="programme_img_text1" v-show="!mask1">
                        <div>
                            <div>多媒体幼儿园方案</div>
                        <div>软件制作，让教室更加互动</div>
                        <div>比同行园区更有竞争力</div>
                        <div>低成本改造</div>
                        </div>
                    </div>
                 </div>
                </div>
        <!-- <div class="programme_btn">
            <el-button type="primary" >即刻制定>></el-button>
        </div> -->
      <div  class="programme_btn">
        <el-popover placement="top" trigger="click" popper-class="mask">
            <div class="mask">
              <div class="mask-left">
                <div class="mask-left-title">
                  <div>在线交谈</div>
                  <div>扫描下方二维码进行产品咨询</div>
                </div>
                <div class="mask-bg"></div>
              </div>
              <div class="mask-right">
                <div class="mask-right-title">
                  <div>客服电话</div>
                  <div>拨打电话立即咨询</div>
                </div>
                <div class="mask-phone">15054896836</div>
              </div>
            </div>
            <el-button class="customer-service-btn" type="primary"  slot="reference">即刻制定>></el-button>
          </el-popover>
      </div>
    </div>

    <div class="problem">
        <div class="problem-title">以下问题，我们都能帮您解决</div>
        <div class="problem-container">
            <div class="container-list">
                <img src="../assets/images/DisplayBusiness/zhengque.png" >
                <div>有工厂，但是没控制器，方案也没思路</div>
            </div>
            <div class="container-list">
                <img src="../assets/images/DisplayBusiness/zhengque.png" alt="">
                <div>我们是接项目的，只有施工人员，没有技术人员</div>
            </div>
            <div class="container-list">
                <img src="../assets/images/DisplayBusiness/zhengque.png" alt="">
                <div>简单控制可以实现，但复杂的每次都要定制</div>
            </div>
            <div class="container-list">
                <img src="../assets/images/DisplayBusiness/zhengque.png" alt="">
                <div>我想将这个项目改为互动项目</div>
            </div>
            <div class="container-list">
                <img src="../assets/images/DisplayBusiness/zhengque.png" alt="">
                <div>我们是做软件的，没有硬件</div>
            </div>
        </div>
    </div>

    <div class="explore">
        <div class="explore-title ft56">意想意创正在探索的</div>
        <div class="explore-content">
            <img src="../assets/images/DisplayBusiness/魔幻方阵3.jpg" alt="">
            <img src="../assets/images/DisplayBusiness/screenshot-20220929-165319.png" alt="">
            <div class="explore-content-text">
                <div class="explore-content-text">互动地砖  矩阵LED屏</div>
                <div>
                    <div>意想意创在美陈行业中不断的探索</div>
                    <div>一款通用型产品，让您的项目高效落地</div>
                    <div>也期待与更有创意的您合作</div>
                </div>
            </div>
        </div>
    </div>

    <div class="contact">
        <div class="contact_title ft56">联系我们，免费获取方案类目</div>
        <div class="contact_container">
          <div class="contact_container_list" style="text-align: center;">
            <div class="logo">
              <img src="../assets/images/DisplayBusiness/logo.png" alt="">
            </div>
            <div class="content">
              请准备好下面的资料  让沟通更高效
            </div>
          </div>
          <div class="question_container">
            <div class="question">
            Q：您的应用环境是什么？/产品是在户外还是室内使用？
          </div>
          </div>
          <div class="question_container">
            <div class="question">
              Q：您的预算有多少 ？/大概控制在什么范围内？
          </div>
          </div>

          <div class="contact_container_list">
            <div class="logo">
              <img src="../assets/images/DisplayBusiness/logo.png" alt="">
            </div>
            <div class="content" >
              <div>我们的资料</div>
              <div>1.美陈画册</div>
              <div>2.产品功能介绍</div>
              <div>3.网红策划方案视频</div>
            </div>
          </div>

          <div class="contact_container_mouse">
            <img src="../assets/images/DisplayBusiness/jiantou.png" alt="">
          </div>
        </div>

    </div>
       
  </div>
</template>

<script>
export default {
    data(){
        return{
            mask:true,
            mask1:true,
        }
    }
};
</script>

<style lang="less" scoped>
.business {
  width: 100%;
  min-width: 1200px;
  position: relative;
  overflow: hidden;
  .suspension{
    position: absolute;
z-index: -1;
left: -744.92px;
top: 4413.72px;
width: 1329.74px;
height: 1290.08px;
opacity: 0.2;
transform: rotate(-358.18616964486176deg);
background: linear-gradient(90deg, rgba(72, 124, 234, 1) 0%, rgba(255, 255, 255, 1) 100%);
filter: blur(100px);
  
  }
  .suspension1{
    position: absolute;
z-index: -1;
left: 1160.85px;
top: 2737.69px;
width: 1022px;
height: 1517.1px;
opacity: 0.2;
transform: rotate(22.780703370558427deg);
background: radial-gradient(50% 63.95%, rgba(73, 124, 235, 0.5) 0%, rgba(73, 124, 235, 0.01) 100%);
filter: blur(100px);
  
  }
  .suspension2{
    position: absolute;
z-index: -1;
left: 524.85px;
top: 904.69px;
width: 1022px;
height: 1517.1px;
opacity: 0.2;
transform: rotate(-268.9418887954041deg);
background: radial-gradient(50% 63.95%, rgba(73, 124, 235, 0.5) 0%, rgba(73, 124, 235, 0.01) 100%);
filter: blur(100px);
  
  }

  .banner {
    width: 100%;
    min-width: 1200px;

    background: url(../assets/images/DisplayBusiness/banner.jpg) no-repeat;
    background-size: cover;
    height: 900px;
    position: relative;
    .banner-container {
      position: absolute;
      top: 290px;
      left: 360px;
      min-width: 580px;
      // width: 500px;
      .banner-content {
        font-size: 28px;
      }
      .banner-title {
        font-size: 72px;
        margin-bottom: 70px;
      }
      .banner-text {
        font-size: 32px;
      }
    }
  }
  .advantage {
    width: 90%;
    margin: auto;
  max-width: 1200px;

    .advantage-title {
      margin: 50px 0;
      text-align: center;
    }
    .advantage-introduce {
      display: flex;
      justify-content: center;
      max-width: 1100px;
      margin: auto;
      .advantage-introduce-term {
        height: 500px;
        width: 360px;
        min-width: 356px;
        padding: 40px;
        box-shadow: 0px 2px 10px 0px rgba(20, 22, 41, 0.1);
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin: auto;
        margin-right: 50px;
        overflow: visible;
        position: relative;
        .advantage-introduce-term-title {
          font-size: 28px;
          color: rgba(5, 105, 255, 1);
        }
        .advantage-introduce-term-img {
          width: 116px;
          height: 116px;
          border-radius: 50%;
          // background: linear-gradient(
          //   to left,
          //   rgba(179, 209, 255, 1),
          //   rgba(10, 108, 255, 0)
          // ) no-repeat ;
          text-align: center;
          margin: auto;
          img {
            object-fit: cover;
            // width: 100%;
            height: 116px;
          }
          
        }
        .advantage-introduce-term-bg{
          position: absolute;
          width: 116px;
          height: 116px;
          border-radius: 50%;
          background: linear-gradient(
            to left,
            rgba(179, 209, 255, 1),
            rgba(10, 108, 255, 0)
          ) no-repeat ;
          top: 40%;
          left: 55%;
          transform: translate(-50%,-50%);
          z-index: -1;
        }
        .advantage-introduce-term-bg1{
          position: absolute;
          width: 116px;
          height: 116px;
          border-radius: 50%;
          background: linear-gradient(
            to left,
            rgba(179, 209, 255, 1),
            rgba(10, 108, 255, 0)
          ) no-repeat ;
          top: 48%;
          left: 51%;
          transform: translate(-50%,-50%);
          z-index: -1;
        }
        .advantage-introduce-term-bg2{
          position: absolute;
          width: 116px;
          height: 116px;
          border-radius: 50%;
          background: linear-gradient(
            to left,
            rgba(179, 209, 255, 1),
            rgba(10, 108, 255, 0)
          ) no-repeat ;
          top: 45%;
          left: 45%;
          transform: translate(-50%,-50%);
          z-index: -1;
        }
        
        
        .advantage-introduce-term-text {
          font-size: 20px;
          text-align: left;
        }
      }
      .advantage-introduce-term:hover {
        background: linear-gradient(
          to top,
          rgba(179, 209, 255, 1),
          rgba(10, 108, 255, 0)
        );
      }
    }
    .advantage-container {
      display: flex;
      width: 100%;
      margin-top: 50px;
      
      flex-direction: column;
      .advantage-container-content {
        display: flex;
        justify-content: center;
        margin: auto;
        margin-bottom: 30px;
        .content-img {
            padding-right: 40px;
            min-width: 560px;
            max-width: 570px;
            // height: 476px;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .content{
            margin-right: 30px;
            .title{
                font-size: 38px;
                margin-bottom: 30px;
            }
            .list-title{
                font-size: 28px;
                display: flex;
              align-items: center;
              position: relative;
              img{
                position: absolute;
                left: -20px;
              }
            }
            .list-text{
                color: rgba(166, 166, 166, 1);
                font-size: 20px;
                margin-bottom: 30px;
            }
        }
      }
    }
  }

  .programme{
    width: 90%;
    margin: auto;
    max-width: 1300px;
    .programme-title{
        margin: 50px 0;
        text-align: center;
    }
    .programme-container{
        display: flex;
        justify-content:center;

        margin-bottom: 50px;
        .container-list{
            padding: 22px 33px;
            color: rgba(166, 166, 166, 1);
            font-size: 20px;
             width: 45%;
            height: 175px;
            max-width: 588px;
            box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.1);
            margin-right: 20px;
            .list_title{
                font-size: 38px;
                color: #000;

            }
        }
    }
    .programme_img{
        display: flex;
        justify-content: center;
        margin: auto;
        .programme_img_container{
             width: 45%;
             max-width: 590px;
            position: relative;
            overflow: hidden;
            margin-right: 20px;
            img{
                width: 100%;
                height: 300px;
                object-fit: cover;
            }
           
            
              .programme_img_text{
                position: absolute;
                color: #fff;
                bottom: 20px;
                left: 20px;
                font-size: 20px;
            }
            .programme_img_text1{
               width: 100%;
                height: 300px;
                background: rgba(49, 93, 189, 0.8);
                position: absolute;
                top: 0;
                bottom: 0;
                >div{
                    color: #fff;
                    position: absolute;
                    top:50% ;
                    left: 20px;
                    transform: translateY(-50%);
                    font-size: 20px;
                    
                }
            }
            
        }
        
      
    }
    .programme_btn{
      width: 95%;
        text-align: right;
        margin-bottom:100px;
        position: relative;
        .el-button{
          width: 300px;
          margin-top: 30px;
          position: absolute;
          right: 0;
        }
    }
  }

  .problem{
    margin: auto;
    max-width: 1300px;
    .problem-title{
        font-size: 56px;
        text-align: center;
        margin-bottom: 50px;
        
    }
    .problem-container{
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            margin: auto;
            width: 90%;
            .container-list{
                width: 45%;
                max-width: 500px;
                font-size: 20px;
                display: flex;
                align-items: center;
                margin-bottom: 30px;
                img{
                    width: 30px;
                    object-fit: cover;
                    margin-right: 10px;
                }
            }
            
        }

  }
  .explore{
    width: 90%;
    margin: auto;
    max-width: 1300px;
    .explore-title{
        margin-bottom: 50px;
        text-align: center;
    }
    .explore-content{
        display: flex;
        justify-content: space-evenly;

        img{
            width: 30%;
            height: 314px;
        }
        .explore-content-text{
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            .explore-content-text{
                font-size: 38px;
            }
            div{
                font-size: 20px;
            }

        }
    }
  }
  .contact{
    width: 90%;
    margin: auto;
    padding-bottom: 50px;
    max-width: 1300px;
    .contact_title{
        text-align: center;
        margin-bottom: 50px;
        margin-top: 50px;
    }
    .contact_container{
      display: flex;
      flex-direction: column;
      position: relative;
      .contact_container_list{

        display: flex;

        .logo{
          width: 100px;
          height: 100px;
          border-radius: 50%;
          box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.1);
          position: relative;
          img{
            width: 60px;
            height: 60px;
            object-fit: cover;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);

          }
        }
        .content{
          margin-left: 20px;
          margin-bottom: 80px;
          color: rgba(255, 255, 255, 1);
          font-size: 24px;
          width: 480px;
          padding: 22px 0;
          border-radius: 0px 69px  69px  69px;
          background: rgba(72, 124, 234, 1);
          transform: translateY(50px);
          text-align: center;
          >div{
            text-align: left;
            padding-left: 125px;
          }
        }
        
      }

      .question_container{
        display: flex;
        justify-content: flex-end;
        .question{
        // width: 740px;
        font-size: 24px;
       border-radius: 69px 0px 69px 69px;
        background: rgba(255, 255, 255, 1);
        border: 3px solid rgba(5, 105, 255, 1);   
        margin-bottom: 50px;
        padding: 22px 30px;
        text-align: center;

      }
      }
      
    }
    .contact_container_mouse{
          position: absolute;
          right: 0px;
          bottom: 0px;
        }
  }
}
</style>
<style lang="less">
.mask {
  width: 500px;
  display: flex;
  justify-content: space-around;
  border-radius: 30px;
  .mask-left {
    display: flex;
    flex-direction: column;
    text-align: left;
    margin-left: 30px;
    .mask-left-title {
      position: relative;

      margin-bottom: 20px;
    }
    .mask-left-title > div:first-child {
      font-size: 24px;
    }
    .mask-left-title::after {
      content: "";
      position: absolute;
      width: 100%;
      height: 2px;
      background: rgba(204, 204, 204, 1);
      bottom: -13px;
    }

    .mask-bg {
      background: url(../assets/images/PageLayout/二维码.png) no-repeat;
      background-size: cover;
      width: 160px;
      height: 160px;
      margin: auto;
    }
  }
  .mask-left::after {
    content: "";
    position: absolute;
    width: 2px;
    height: 80%;
    background: #cccccc;
    right: 50%;
  }
  .mask-right {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: auto;
    text-align: left;
    height: 100%;

    .mask-right-title {
      position: relative;
      margin-bottom: 20px;
    }
    .mask-right-title > div:first-child {
      font-size: 24px;
    }
    .mask-right-title::after {
      content: "";
      position: absolute;
      width: 100%;
      height: 2px;
      background: rgba(204, 204, 204, 1);
      bottom: -13px;
    }
    .mask-phone {
      width: 160px;
      height: 160px;
      color: rgba(5, 105, 255, 1);
      font-size: 24px;
    }
  }
}
</style>