import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

//解决路由重定向报错问题
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
  }
  
const routes = [
  {
    path: '/Exhibition',
    component: () =>import('../views/Exhibition.vue') //展厅业务
  },
  {
    path: '/CoreTools',
    component: () => import( '../views/CoreTools.vue') //核心工具
  },
  {
    path: '/DisplayBusiness',
    component: () => import( '../views/DisplayBusiness.vue') //互动美陈业务
  },
  {
    path: '/PageLayout',
    component: () => import( '../views/PageLayout.vue') //其他业务
  },
  {
    path: '/Realistic',
    component: () => import( '../views/Realistic.vue') //实景业务
  },
  {
    path: '/Download',
    component: () => import( '../views/Download.vue') //资料下载
  },
  {
    path: '/DownloadImmerse',
    name:'DownloadImmerse',
    component: () => import( '../views/DownloadImmerse.vue') //资料下载
  },
  {
    path:'/',
    redirect:'/CoreTools'
  }
]



const router = new VueRouter({
  routes
})

export default router
