import request from '@/utils/request'

export function fileFolder() {
  return request({
    url: 'mall/api/oss/fileFolder?fileName=固件',
    method: 'get'
  })
}

export function listFile(data) {
  return request({
    url: 'mall/api/oss/listFile2?fileHost=固件/' + data,
    method: 'get'
  })
}

export function toolBox() {
  return request({
    url: 'mall/api/oss/listFile2?fileHost=工具箱',
    method: 'get'
  })
}