<template>
  <div class="pagelayout">
    <!-- banner -->
    <el-carousel height="950px" >
      <el-carousel-item>
        <div class="banner">
          <div class="banner-left">
            <div class="colorfff banner-left-title">
              <div class="banner-left-title1">
                <div>“亮起来”<span class="color006">时代已过</span></div>
                <div><span class="color006">下一步让门头</span>“活起来”</div>
              </div>
              <div class="shadow">
                <div>“亮起来”<span class="color006">时代已过</span></div>
                <div><span class="color006">下一步让门头</span>“活起来”</div>
              </div>
            </div>
            <div class="colorfff anner-left-content">
              降低动态门头成本，提高吸睛效率，百元搭建属于你的个性化门头
            </div>
            <div>
              <el-button type="primary" round @click="jump(0)"
                >跳转详情 <i class="el-icon-right"></i></el-button>
            </div>
          </div>
          <div class="banner-content">
            <div>一起动起来!</div>
          </div>
          <div class="banner-right">
            <el-button type="primary" round >发光字门头动态升级方案</el-button>
          </div>
        </div>
      </el-carousel-item>

      <el-carousel-item >
        <div class="banner1">
          <div class="banner1_left">
            <div class="banner1_title ft72">
              <span>“互动”</span>赋予<span>“活动”</span>全新活力
            </div>
            <div class="banner1_text">
              一站式活动道具打造，不只是道具，更是全新的活动方案
            </div>
            <div class="banner1_btn">
              <el-button type="primary" round @click="jump(1)">跳转详情 <i class="el-icon-right"></i></el-button>
            </div>
          </div>
          <div class="banner1_right">
            <img src="../assets/images/PageLayout/banner1.png" alt="" />
          </div>
          <div class="banner1_bottom">
            <el-button type="primary" round >活动道具</el-button>
          </div>
        </div>
      </el-carousel-item>
      <el-carousel-item >
        <div class="banner1">
          <div class="banner1_left">
            <div class="banner1_title ft72">
              <span>“Smart模块”</span>为桌游赋能新操作
            </div>
            <div class="banner1_text ">
              一步提升桌游沉浸性，体验裸眼VR效果解放DM双手，把握全场节奏
            </div>
            <div class="banner1_btn">
              <el-button type="primary" round @click="jump(2)">跳转详情 <i class="el-icon-right"></i></el-button>
            </div>
          </div>
          <div class="banner1_right">
            <img  src="../assets/images/PageLayout/banner2.png" alt="" />
          </div>
          <div class="banner1_bottom">
            <el-button type="primary" round>桌面剧本杀机关</el-button>
          </div>
        </div>
      </el-carousel-item>
    </el-carousel>

    <!-- 发光文字 -->
    <div class="dynamic floor" id="dynamic">
      <div class="ft56 dynamic-title">意想意创可提供发光字“动态”升级方案</div>
      <div class="dynamic-content">
        <div>
          发光字作为当下门头招牌主流的夜间引流产品，已被广泛应用于各种门头;
        </div>
        <div>
          随着发光字越来越普遍的应用，发光字门头在“同质化”的路边已经很难脱颖而出；
        </div>
        <div>
          Smart模块可以为静态的发光字提供“动态”的升级方案，通过各种动态变化起到脱颖而出的引流效果。
        </div>
      </div>
      <div class="dynamic-container">
        <div
          class="dynamic-container-restaurant"
          @mouseover="mask = true"
          @mouseleave="mask = false"
        >
          <img
            v-show="!mask"
            src="../assets/images/PageLayout/餐厅.jpg"
            alt=""
          />
          <img
            v-show="mask"
            src="../assets/images/PageLayout/餐厅.gif"
            alt=""
          />
          <div v-show="!mask" class="dynamic-container-restaurant-text">
            路边餐厅
          </div>
          <div v-show="mask" class="dynamic-container-active colorfff">
            <div>路边餐厅</div>
            <div>路边餐厅的灯光动起来，吸引更多食客进入。</div>
          </div>
        </div>
        <div
          class="dynamic-container-restaurant"
          @mouseover="mask1 = true"
          @mouseleave="mask1 = false"
        >
          <img
            v-show="!mask1"
            src="../assets/images/PageLayout/咖啡店.jpg"
            alt=""
          />
          <img
            v-show="mask1"
            src="../assets/images/PageLayout/咖啡店.gif"
            alt=""
          />
         
          <div v-show="!mask1" class="dynamic-container-restaurant-text">
            街边饮品店
          </div>
          <div v-show="mask1" class="dynamic-container-active colorfff">
            <div>街边饮品店</div>
            <div>饮品店突出标志可以设为闪烁，用动态吸引目光。</div>
          </div>
        </div>
        <div
          class="dynamic-container-restaurant"
          @mouseover="mask3 = true"
          @mouseleave="mask3 = false"
        >
          <img
            v-show="!mask3"
            src="../assets/images/PageLayout/鞋店.jpg"
            alt=""
          />
          <img
            v-show="mask3"
            src="../assets/images/PageLayout/鞋店.gif"
            alt=""
          />
          <div v-show="!mask3" class="dynamic-container-restaurant-text">
            潮牌店铺
          </div>
          <div v-show="mask3" class="dynamic-container-active colorfff">
            <div>潮牌店铺</div>
            <div>增加闪烁，可以在logo中找出突出字母进行加强。</div>
          </div>
        </div>
        <div
          class="dynamic-container-restaurant"
          @mouseover="mask2 = true"
          @mouseleave="mask2 = false"
        >
          <img
            v-show="!mask2"
            src="../assets/images/PageLayout/室内.jpg"
            alt=""
          />
          <img
            v-show="mask2"
            src="../assets/images/PageLayout/室内.gif"
            alt=""
          />
          <div v-show="!mask2" class="dynamic-container-restaurant-text">
            室内店铺
          </div>
          <div v-show="mask2" class="dynamic-container-active colorfff">
            <div>室内店铺</div>
            <div>增加闪烁，同样是远距离的3家店铺，一目了然引流店铺。</div>
          </div>
        </div>
      </div>
      <div class="dynamic-effect">
        <div class="dynamic-effect-container">
          <div class="dynamic-effect-container-title">目光停留时间</div>
          <div class="dynamic-effect-container-theme">+7S</div>
          <div class="dynamic-effect-container-line"></div>
          <div class="dynamic-effect-container-text">
            动态发光字抓住行人好奇心理，提升行人视觉停留，由行人到访店进行转化。
          </div>
        </div>
        <div class="dynamic-effect-container">
          <div class="dynamic-effect-container-title">信息传达率</div>
          <div class="dynamic-effect-container-theme">+45%</div>
          <div class="dynamic-effect-container-line"></div>
          <div class="dynamic-effect-container-text">
            吸引人的目光注意，好的动态更好的传达品牌信息。
          </div>
        </div>
        <div class="dynamic-effect-container">
          <div class="dynamic-effect-container-title">夜间引流效率</div>
          <div class="dynamic-effect-container-theme">+60%</div>
          <div class="dynamic-effect-container-line"></div>
          <div class="dynamic-effect-container-text">
            让发光字在同质化的路边脱颖而出，提升引流效率。
          </div>
        </div>
        <div class="dynamic-effect-container" style="margin-right: 0;">
          <div class="dynamic-effect-container-title">远处吸睛效果</div>
          <div class="dynamic-effect-container-theme">+80%</div>
          <div class="dynamic-effect-container-line"></div>
          <div class="dynamic-effect-container-text">
            根据人眼对于动态物体更为敏感的理论，相对于毫无吸睛效果的原始门头，动态发光字门头可以提升80%的远处吸睛效果。
          </div>
        </div>
      </div>
    </div>

    <div class="central-control">
      <div class="central-content mgat" style="height:860px;">
        <!-- <h2 class="ft56">中控解决方案</h2> -->
        <div class="central-nav">
          <i class="el-icon-back" @click="switchBtn('actv','centralNav',-1)"></i>
          <div
            class="color7d"
            :class="{ activacolor: actv == index }"
            v-for="(item, index) in centralNav"
            :key="index"
            @click="handel(index)"
          >
            {{ item }}
          </div>
          <i class="el-icon-right" @click="switchBtn('actv','centralNav',1)"></i>
        </div>

        <!-- 选项卡 -->
        <div class="tablecard" v-show="actv === 0">
          <div class="tablecard-title">
            <div>现有发光字门头无损升级方案</div>
            <div>意想意创让您的门头更引流</div>
          </div>
          <div class="tablecard-content">
            <div>
              <img src="../assets/images/PageLayout/图片3.png" alt="" />
            </div>
            <div class="tablecard-content-left">
              <div>无损升级</div>
              <div>降低80%二次门头安装费用</div>
              <div>
                ◦ 充分利用正在使用中的发光字门头，无需拆除，降低60%~80%的二次安装成本。
              </div>
            </div>
          </div>
        </div>
        <div class="tablecard" v-show="actv === 1">
          <div class="tablecard-title">
            <div>现有发光字门头无损升级方案</div>
            <div>意想意创让您的门头更引流</div>
          </div>
          <div class="tablecard-content">
            <div>
              <img src="../assets/images/PageLayout/图片4.png" alt="" />
            </div>
            <div class="tablecard-content-left">
              <div>安装仅需两根线</div>
              <div>自己上手也能行</div>
              <div>
                ◦ 控制设备可以灵活加载数百种动态变化，并可以根据实际需求进行定制展示。
              </div>
            </div>
          </div>
        </div>
        <div class="tablecard" v-show="actv === 2">
          <div class="tablecard-title">
            <div>现有发光字门头无损升级方案</div>
            <div>意想意创让您的门头更引流</div>
          </div>
          <div class="tablecard-content">
            <div>
              <img style="height:300px" src="../assets/images/PageLayout/发光字.png" alt="" />
            </div>
            <div class="tablecard-content-left">
              <div>动态效果可随时更换</div>
              <div>每天都是新体验</div>
              <div>
                ◦ 控制设备可以灵活加载数百种动态变化，并可以根据实际需求进行定制展示。
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
<!-- 道具升级 -->
    <div class="Solution floor">
      <div class="ft56">意想意创提供活动道具解决方案</div>
      <div>随着沉浸式互动的逐渐普及</div>
      <div>
        越来越多商场庆典活动、店铺开业、商家促销活动、售楼处抽奖等需要活动道具的地方开始使用互动版活动道具。
      </div>
      <div>
        意想意创具有丰富的活动道具资源库，可以为各类场景提供活动道具，也可以为各种道具提供定制服务。
      </div>
    </div>

    <div class="central-control">
      <div class="central-content mgat">
        <!-- <h2 class="ft56">中控解决方案</h2> -->
        <div class="central-nav">
          <i class="el-icon-back" @click="switchBtn('actv1','centralNav1', -1)"></i>
          <div
            class="color7d"
            :class="{ activacolor: actv1 == index }"
            v-for="(item, index) in centralNav1"
            :key="index"
            @click="handel1(index)"
          >
            {{ item }}
          </div>
          <i class="el-icon-right" @click="switchBtn('actv1','centralNav1', 1)"></i>
        </div>

        <!-- 选项卡 -->
        <div class="tablecard" v-show="actv1 === 0">
          <div class="tablecard-title">
            <div>各式活动道具满足各种场景活动使用</div>
          </div>
          <div class="tablecard-content">
            <div>
              <img src="../assets/images/PageLayout/图片5.png" alt="" />
            </div>
            <div class="tablecard-content-left">
              <div>拍十秒</div>
              <div>手机游戏的互动新玩法</div>
              <div>
                ◦ 2018年火爆各大店铺的抽免单活动道具——拍十秒，引发了一阵网络热潮，众多体验时的客人纷纷拍视频发送朋友圈、抖音等，吸引更多的客人到店消费抽奖。正是这种新奇有趣的互动道具，将老的手速游戏通过道具的形式，搬送到现实生活中，结合优惠活动，给到客户新鲜刺激的体验感，引发消费及引流。
              </div>
            </div>
          </div>
        </div>
        <div class="tablecard" v-show="actv1 === 1">
          <div class="tablecard-title">
            <div>各式活动道具满足各种场景活动使用</div>
          </div>
          <div class="tablecard-content">
            <div>
              <img src="../assets/images/PageLayout/图片6.png" alt="" />
            </div>
            <div class="tablecard-content-left">
              <div>挑战代替抽奖</div>
              <div>互动体验感MAX</div>
              <div>
                ◦ 2019年夏天火爆整个夏日的眼疾手快机关，无论商场活动还是摆摊现场，总是人山人海的排队场景，极好的为店铺等进行了引流。
              </div>
            </div>
          </div>
        </div>
        <div class="tablecard" v-show="actv1 === 2">
          <div class="tablecard-title">
            <div>各式活动道具满足各种场景活动使用</div>
          </div>
          <div class="tablecard-content">
            <div>
              <img src="../assets/images/PageLayout/图片7.png" alt="" />
            </div>
            <div class="tablecard-content-left">
              <div>商场选自困难症终结者</div>
              <div>幸运大转盘升级版</div>
              <div>
                ◦ 可以设置在商场、店铺的墙面做美化、装饰作用。大转盘玩法升级版，带给体验者一场特殊的灯光体验秀。
              </div>
            </div>
          </div>
        </div>
        <div class="tablecard" v-show="actv1 === 3">
          <div class="tablecard-title">
            <div>各式活动道具满足各种场景活动使用</div>
          </div>
          <div class="tablecard-content">
            <div>
              <img src="../assets/images/PageLayout/图片8.png" alt="" />
            </div>
            <div class="tablecard-content-left">
              <div>虚幻照进显示</div>
              <div>传说中的摇钱树来到活动现场</div>
              <div>
                ◦ 真实模拟神话摇钱树的功能，让美好传说照进现实，美好的寓意适合各种场景的应用，在不使用的时候可以当做装饰使用，黄金色的树木映照活动现场的富丽堂皇。
              </div>
            </div>
          </div>
        </div>
        <div class="tablecard" v-show="actv1 === 4">
          <div class="tablecard-title">
            <div>各式活动道具满足各种场景活动使用</div>
          </div>
          <div class="tablecard-content">
            <div>
              <img src="../assets/images/PageLayout/图片9.png" alt="" />
            </div>
            <div class="tablecard-content-left">
              <div>感受魔法的神奇魅力</div>
              <div>带给玩家独特的神体验</div>
              <div>
                ◦ 手持魔法棒，挥舞出指定的图形，所有的格子就会按照规律动作，最终推出一个格子，格子内即是玩家获得的礼物。
              </div>
            </div>
          </div>
        </div>
        <!-- 咨询客服 -->
        <div class="consulting">
          <div class="consulting-title">更多方案 · 请联系我们进行咨询哦</div>
          <div
            class="consulting-container"
            @mouseover="maskcard = true"
            @mouseleave="maskcard = false"
          >
            <el-button type="primary" round>立即联系</el-button>
            <div class="mask" v-show="maskcard">
              <div class="mask-left">
                <div class="mask-left-title">
                  <div>在线交谈</div>
                  <div>扫描下方二维码进行产品咨询</div>
                </div>
                <div class="mask-bg"></div>
              </div>
              <div class="mask-right">
                <div class="mask-right-title">
                  <div>客服电话</div>
                  <div>拨打电话立即咨询</div>
                </div>
                <div class="mask-phone">15054896836</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 剧本杀 -->
    <div class="provide floor">
      <div class="ft56">意想意创可提供桌面剧本杀专属机关方案</div>
      <div class="provide-list">
        <div>
          桌面剧本杀是一种注重推理的互动桌面游戏，每个剧本都是一个不同的世界，通过读剧本、发言
        </div>
        <div>将玩家带入不同的世界，并且使用各种道具卡片推理凶手完成任务。</div>
        <div>
          随着线下剧本店铺的发展，许多店铺开始将机关引入到桌面剧本杀中，为玩家带入剧本场景及角
        </div>
        <div>
          色提供更好的环境；意想意创针对于此推出了一系列的适用于桌面剧本杀的机关服务。
        </div>
      </div>
      <div class="provide-nav">
        <div :class="{ active: provideActive == 0 }" @click="provideActive = 0">
          剧本杀智控箱（专为桌面剧本杀打造的控制设备）
        </div>
        <div :class="{ active: provideActive == 1 }" @click="provideActive = 1">
          全息沉浸式剧本杀
        </div>
      </div>
      <div class="provide-title" v-show="provideActive == 0">
        您是不是经常遇到以下问题
      </div>
      <div class="provide-container" v-show="provideActive == 0">
        <div>
          <div class="container-title">音效影响流程感受</div>
          <div>
            使用蓝牙音箱进行背景音效播放，错播、漏播频发，影响玩家体验感，且不同剧本需要播放不同音效，切换寻找音效不方便。
          </div>
        </div>
        <div>
          <div class="container-title">音效替换太复杂</div>
          <div>采用机关进行音效播放，开不同剧本进行音效替换太复杂。</div>
        </div>
        <div>
          <div class="container-title">增加机关=换新</div>
          <div>
            每次增加机关等于房间内机关换新，无法对应不同本子触发不同的灯光、锁等效果。
          </div>
        </div>
      </div>
    </div>
    <div class="mantle" v-show="provideActive == 1">
      <div class="mantle-top">
        <div class="mantle-top-content">
          <div>
            采用沉浸式投影技术，通过多通道的投影融合，巧妙利用虚拟现实、全息投影等多媒体技术，将
          </div>
          <div>
            投影画面投射到大型或者多面的投影幕上，营造出缤纷的立体影像，结合剧本素材的全景展示，
          </div>
          <div>让玩家体验到身临其境的震撼感。</div>
        </div>
        <div class="mantle-top_container">
          <div class="mantle-top-img">
            <img src="../assets/images/PageLayout/jbs.png" alt="" />
            <img src="../assets/images/PageLayout/jbs.png" alt="" />
          </div>
          <div class="mantle-bottom">
            <div class="mantle-bottom-title">
              沉浸式剧本杀为您带来意想不到的效果
            </div>
            <div class="mantle-bottom-container">
              <div class="mantle-bottom-container-content">
                <div class="title">全息剧本杀</div>
                <div>◦ 场景可以灵活调整，一个房间对应不同类型主题。</div>
                <div>◦ 打卡拍照留念，网红引流。</div>
                <div>◦ 设备可反复利用，换店无需换设备。</div>
              </div>
              <div class="mantle-bottom-container-content">
                <div class="title">传统剧本杀</div>
                <div>◦ 每个房间一种装修，无法结合剧本提供沉浸式的环境体验。</div>
                <div>◦ 缺少特色，无精美装修无法引起玩家拍照兴致。</div>
                <div>◦ 装修一次性，换店需经历破坏性拆除。</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="central-control">
      <div class="central-content mgat">
        <div class="central-nav">
          <i class="el-icon-back"  @click="switchBtn('actv2','centralNav2', -1)"></i>
          <div
            class="color7d"
            :class="{ activacolor: actv2 == index }"
            v-for="(item, index) in centralNav2"
            :key="index"
            @click="handel2(index)"
          >
            {{ item }}
          </div>
          <i class="el-icon-right"  @click="switchBtn('actv2','centralNav2', 1)"></i>
        </div>

        <!-- 选项卡 -->
        <div class="tablecard" v-show="actv2 === 0">
          <div class="tablecard-title">
            <div>剧本杀智控箱解决桌游智控难题</div>
            <div>
              针对于桌面剧本杀推出了“剧本杀智控箱”，每个房间仅需一个智控箱
            </div>
          </div>
          <div class="tablecard-content">
            <div>
              <img style=" height:417px" src="../assets/images/PageLayout/图片12.png" alt="" />
            </div>
            <div class="tablecard-content-left">
              <div>智能音效</div>
              <div>全屋智能音效仅需1根音频线</div>
              <div>
                ◦ 背景音效和故事音效播放仅需一根音频线即可进行播放，同时播放、插播等播放方式皆可设置。
              </div>
              <div>◦ 手机调控音效，一键控制音效播放。</div>
              <div>
                ◦ 快捷键随时调整音效播放次序，随时调取不同的音效进行播放。
              </div>
            </div>
          </div>
        </div>
        <div class="tablecard" v-show="actv2 === 1">
          <div class="tablecard-title">
            <div>剧本杀智控箱解决桌游智控难题</div>
            <div>
              针对于桌面剧本杀推出了“剧本杀智控箱”，每个房间仅需一个智控箱
            </div>
          </div>
          <div class="tablecard-content">
            <div class="tablecard-content_img">
              <img style=" height:483px" src="../assets/images/PageLayout/图片11.png" alt="" />
            </div>
            <div class="tablecard-content-left">
              <div>一个中控箱</div>
              <div>多款剧本设定随时更换</div>
              <div>
                ◦ 剧本可以提前设定存储云端，玩家进场前可根据选择剧本随时调整替换设置，无需费力寻找，一键轻松搞定整场操作。
              </div>
            </div>
          </div>
        </div>
        <div class="tablecard" v-show="actv2 === 2">
          <div class="tablecard-title">
            <div>剧本杀智控箱解决桌游智控难题</div>
            <div>
              针对于桌面剧本杀推出了“剧本杀智控箱”，每个房间仅需一个智控箱
            </div>
          </div>
          <div class="tablecard-content">
            <div class="tablecard-content_img">
              <img style=" height:483px" src="../assets/images/PageLayout/图片11.png" alt="" />
            </div>
            <div class="tablecard-content-left">
              <div>多机关复用</div>
              <div>模块化拓展</div>
              <div>
                ◦ 针对不同的剧本，云端存储功能可保证不同场次调用的机关不同，保证同一房间，场场都有新体验。
              </div>
              <div>
                ◦ 手机控制灯光、手机控制开锁，皆可在其中实现。
              </div>
              <div>
                ◦ 剧本杀智控箱可接入Smart模块，增加机关不用慌，将模块接入智控箱即可实现。
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="suspension"></div>
    <div class="suspension1"></div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      mask: false,
      mask1: false,
      mask2: false,
      mask3: false,
      maskcard: false,
      provideActive: 0,
      actv: 0,
      actv1: 0,
      actv2: 0,
      centralNav: ["无损升级", "极简安装", "快速升级"],
      centralNav2: ["智能音效", "云端剧本", "多机关复用"],
      centralNav1: ["拍十秒", "眼疾手快", "心愿墙", "摇钱树", "礼物盒子"],
      num:0,
    };
  },
  methods: {
    handel(index) {
      this.actv = index;
      
    },
    handel1(index) {
      this.actv1 = index;
    },
    handel2(index) {
      this.actv2 = index;
    },
    switchBtn(key,arr,num){
          if(this[key] + num <0){
            this[key] = (this[arr].length-1)
        return
        }
        if(this[key]+num>this[arr].length-1){
          this[key] = 0
        return
      }
      this[key]+=num
    },
  
    jump(index){
      let floorAll = document.getElementsByClassName('floor')
      window.scrollTo({
        top:floorAll[index].offsetTop,
        left:0,
        behavior:'smooth'
        
      })
    }
  },
};
</script>

<style lang="less" scoped>
/deep/ .el-carousel__indicators--horizontal {
    right: 379px;
    top: 300px;
    display: flex;
    flex-direction: column;
    left: 0;
    transform: none;
    align-items: flex-end;
}
/deep/.el-carousel__button {
    display: block;
    opacity: .48;
    width: 10px;
    height: 10px;
    background-color: rgba(25, 51, 89, 1);
    border: none;
    outline: 0;
    padding: 0;
    margin: 0;
    cursor: pointer;
    transition: .3s;
    border-radius: 50%;
}
/deep/ .el-carousel__indicator--horizontal {
    display: inline-block;
    padding: 5px 4px;
}
/deep/ .el-carousel__indicator.is-active button {
    opacity: 1;
    height: 120px;
    border-radius: 20px;
}

html,body{
  scroll-behavior: smooth;
}
.pagelayout {
  width: 100%;
  min-width: 1200px;
  position: relative;
  overflow: hidden;
  .suspension{
    position: absolute;
z-index: -1;
left: -502px;
    top: 1794px;
width: 1275px;
height: 1152px;
background: radial-gradient(50% 50%, rgba(5, 105, 255, 0.2) 0%, rgba(5, 105, 255, 0) 100%);
    
  }
  .suspension1{
    position: absolute;
    z-index: -1;
    left: 495px;
top: 3202px;
width: 1947px;
height: 2210px;
opacity: 1;
background: radial-gradient(50% 50%, rgba(5, 105, 255, 0.1) 0%, rgba(5, 105, 255, 0) 100%);
  }
  .banner {
    width: 100%;
    height: 950px;
    background: url(../assets/images/PageLayout/banner.png) no-repeat center;
    background-size: cover;
    display: flex;
    position: relative;
    .banner-left {
      padding: 40px;
      background: rgba(6, 23, 48, 0.7);
      height: 100%;
      width: 700px;
      margin-left: 100px;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;

      .banner-left-title {
        font-size: 56px;
        font-weight: bold;
        position: relative;
        z-index: 1;
        .banner-left-title1 {
          z-index: 99;
        }
        .shadow {
          position: absolute;
          color: transparent;
          -webkit-text-fill-color: rgba(255, 255, 255, 0);
          -webkit-text-stroke: 1px #fff;
          bottom: -6px;
          right: 13px;
          z-index: -1;
          width: 600px;
        }
      }
      .anner-left-content {
        font-size: 32px;
      }
    }

    .banner-content {
      background: url(../assets/images/PageLayout/对话框.png) no-repeat;
      width: 350px;
      height: 276px;
      position: absolute;
      color: #fff;
      font-weight: bold;
      font-size: 48px;
      top: 79px;
      left: 661px;
      div {
        transform: rotate(17deg);
        position: absolute;
        top: 42px;
        left: 34px;
      }
    }
    .banner-right {
      position: absolute;
      right: 100px;
      bottom: 50px;
    }
  }
  .banner1 {
    min-width: 1200px;
    width: 100%;
    background: rgba(244, 248, 255, 1);
    height: 950px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    // padding: 0 100px;
    .banner1_left {
      // padding-left: 100px;
      margin-left: 200px;
      .banner1_title {
        // font-size: 56px;
        font-weight: bold;
        width: 560px;
        margin-bottom: 60px;
        span {
          color: rgba(5, 105, 255, 1);
        }
      }
      .banner1_text {
        font-size: 32px;
        width: 560px;
        margin-bottom: 60px;
        color: rgba(105, 105, 105, 1);
      }
    }
    .banner1_right{
      height: 650px;
      img{
        height: 100%;
        object-fit: cover;
      }
    }
    .banner1_bottom{
      position: absolute;
      right: 100px;
      bottom: 50px;
    }
  }

  .dynamic {
    width: 90%;
    text-align: center;
    margin: auto;
    margin-bottom: 30px;
    max-width: 1300px;
    .dynamic-title {
      margin: 30px 0;
    }

    .dynamic-content {
      color: rgba(107, 107, 107, 1);
      font-size: 20px;
      margin-bottom: 30px;
    }
    .dynamic-container {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      
      .dynamic-container-restaurant {
        width: 45%;
        max-width: 592px;
        height: 379px;
        position: relative;
        margin-bottom: 50px;
        border-radius: 15px;
        overflow: hidden;
       
        img {
          width: 100%;
          height: 100%;
        }
       
        .dynamic-container-restaurant-text {
          width: 174px;
          height: 54px;
          color: #fff;
          border-radius: 0px 30px 0px 30px;
          text-align: center;
          line-height: 56px;
          font-size: 20px;
          background: rgba(5, 105, 255, 1);
          position: absolute;
          bottom: 26px;
          left: 15px;
        }
      }
      .dynamic-container-restaurant:nth-child(odd) {
        margin-right: 32px;
      }
      .dynamic-container-active {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: url(../assets/images/PageLayout/矩形6.png) no-repeat ;
        padding-left: 40px;
        padding-bottom: 40px;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        div {
          font-size: 20px;
          text-align: left;
          color: rgba(189, 189, 189, 1);
        }
        div:first-child {
          color: #fff;
          font-size: 30px;
          margin-bottom: 20px;
        }
      }
    }

    .dynamic-effect {
      display: flex;
      justify-content: center;
      width: 100%;
      .dynamic-effect-container {
        width: 280px;
        background: url(../assets/images/PageLayout/dynamicbg.png) no-repeat
          center top 30px;
        height: 420px;
        padding: 30px;
        box-shadow: 0px 2px 8px 0px rgba(3, 24, 56, 0.3);
        border-radius: 20px;
        margin-right: 53px;
        .dynamic-effect-container-title {
          font-size: 30px;
          color: rgba(5, 105, 255, 1);
          text-align: center;
        }
        .dynamic-effect-container-theme {
          font-size: 80px;
          width: 205px;
          font-weight: 400;
          color: rgba(0, 0, 0, 1);
         
          margin-bottom: 30px;
        }
        .dynamic-effect-container-line {
          height: 3px;
          background-color: rgba(38, 118, 238, 1);
          margin-bottom: 20px;
        }
        .dynamic-effect-container-text {
          font-size: 18px;
          color: rgba(107, 107, 107, 1);
          text-align: left;
          min-height: 150px;
        }
      }
    }
  }
  .central-control {
    width: 100%;
    background: #e9eff8;
    padding-bottom: 30px;
    margin-bottom: 30px;
    // margin-top: 40px;
    .central-content {
      width: 90%;
      display: flex;
      flex-direction: column;
      align-items: center;
      h2 {
        margin-top: 50px;
      }
      .central-nav {
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        background: #fff;
        width: 900px;
        height: 100px;
        font-size: 20px;
        position: relative;
        box-shadow: 0 0 5px #d7d7d7;
        border-radius: 20px;
        margin-top: 60px;
        margin-bottom: 40px;
        i{
          cursor:pointer;
        }
        .activacolor {
          color: #000;
          position: relative;
          text-align: center;
        }
        .activacolor::after {
          content: " ";
          position: absolute;
          left: 15%;
          bottom: -36px;
          width: 50%;
          height: 1px;
          border: 5px solid transparent;
          border-bottom: 5px solid #006aff;
        }
        div {
          cursor: pointer;
        }
        i {
          width: 30px;
          line-height: 70px;
          background: #b4d4ff;
          color: #fff;
        }
        i:first-child {
          border-radius: 10px 0 0 10px;
          text-align: right;
          position: absolute;
          left: -30px;
        }
        i:last-child {
          border-radius: 0 10px 10px 0;
          position: absolute;
          right: -30px;
        }
      }
      //选项卡
      .tablecard {
        display: flex;
        flex-direction: column;
        align-items: center;
        .tablecard-title {
          color: rgba(107, 107, 107, 1);
          font-size: 20px;
          text-align: center;
          margin-bottom: 30px;
          div:first-child {
            font-size: 45px;
            color: #000;
            margin-bottom: 30px;
          }
          div:last-child{
            margin-bottom: 40px;
          }
        }
        .tablecard-content {
          display: flex;
          justify-content: center;
          margin-bottom: 30px;
          .tablecard-content_img{
            width: 650px;
            height: 471px;
            background: url(../assets/images/PageLayout/图片10.png) no-repeat;
            background-size: cover;
            display: flex;
            justify-content: center;
          }

          img {
            height: 360px;
            border-radius: 30px;
            // margin-right: 159px;
            object-fit: cover;
          }

          .tablecard-content-left {
            display: flex;
            flex-direction: column;
            justify-content: center;
            text-align: left;
            margin-left: 159px;
            div {
              font-size: 38px;
            }
            div:last-child {
              font-size: 20px;
              margin-top: 30px;
              max-width: 500px;
              text-indent: -0.5em;
            }
            div:nth-child(3) {
              font-size: 20px;
              margin-top: 30px;
              max-width: 500px;
              text-indent: -0.5em;
            }
            div:nth-child(4) {
              font-size: 20px;
              margin-top: 30px;
              width: 500px;
              text-indent: -0.5em;
            }
          }
        }

       
      }
      //咨询客服模块
      .consulting {
        width: 500px;
        text-align: center;
        .consulting-title {
          margin-top: 60px;
          margin-bottom: 40px;
        }
        .consulting-container {
          position: relative;
          .el-button {
            width: 500px;
            height: 54px;
            background-color: rgba(5, 105, 255, 1);
            border-radius: 100px;
            margin-bottom: 90px;
          }
          .mask {
            background: #fff;
            width: 600px;
            height: 300px;
            box-shadow: 0px 2px 3px 0px #d5d5d5;
            display: flex;
            padding: 30px 50px;
            position: absolute;
            bottom: 178px;
            left: -78px;

            .mask-left {
              display: flex;
              flex-direction: column;
              text-align: left;

              .mask-left-title {
                position: relative;

                margin-bottom: 20px;
              }
              .mask-left-title > div:first-child {
                font-size: 24px;
              }
              .mask-left-title::after {
                content: "";
                position: absolute;
                width: 120%;
                height: 2px;
                background: rgba(204, 204, 204, 1);
                bottom: -13px;
              }
              .mask-bg {
                background: url(../assets/images/PageLayout/二维码.png)
                  no-repeat;
                background-size: cover;
                width: 160px;
                height: 160px;
                margin: auto;
              }
            }
            .mask-left::after {
              content: "";
              position: absolute;
              width: 2px;
              height: 80%;
              background: #cccccc;
              right: 50%;
            }
            .mask-right {
              margin: auto;
              text-align: left;
              margin-right: 30px;

              .mask-right-title {
                position: relative;
                margin-bottom: 20px;
              }
              .mask-right-title > div:first-child {
                font-size: 24px;
              }
              .mask-right-title::after {
                content: "";
                position: absolute;
                width: 120%;
                height: 2px;
                background: rgba(204, 204, 204, 1);
                bottom: -13px;
              }
              .mask-phone {
                width: 160px;
                height: 160px;
                color: rgba(5, 105, 255, 1);
                font-size: 24px;
              }
            }
          }
          .mask::after {
            content: "";
            border: 30px solid transparent;
            border-top-color: #fff;
            position: absolute;
            height: 1px;
            width: 1px;
            bottom: -60px;
            left: 275px;
          }
        }
      }
    }
  }

  .Solution {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 90%;
    height: 400px;
    color: rgba(107, 107, 107, 1);
    font-size: 20px;
    line-height: 30px;
    margin: auto;
    div:first-child {
      color: #000;
      margin-bottom: 30px;
    }
  }

  .provide {
    width: 90%;
    max-width: 1300px;
    display: flex;
    flex-direction: column;
    margin: auto;
    text-align: center;
    margin-top: 100px;
    .provide-list {
      color: rgba(107, 107, 107, 1);
      margin-top: 26px;
      margin-bottom: 60px;
    }

    .provide-nav {
      display: flex;
      height: 50px;
      font-size: 32px;
      margin-top: 50px;
      width: 100%;
      margin: auto;
      .active {
        background: rgba(5, 105, 255, 1);
        color: #fff;
        border: none;
      }
      div {
        border: 2px solid rgba(107, 107, 107, 1);
        color: rgba(107, 107, 107, 1);
        line-height: 50px;
        border-radius: 25px;
        cursor: pointer;
      }
      div:first-child {
        flex: 2;
      }
      div:last-child {
        flex: 1;
      }
    }

    .provide-title {
      font-size: 45px;
      margin-top: 90px;
      margin-bottom: 50px;
    }

    .provide-container {
      display: flex;
      margin: auto;
      margin-bottom: 90px;
    }
    .provide-container > div {
      width: 350px;
      height: 361px;
      box-shadow: 0px 2px 8px 0px #7f7f7f;
      margin-right: 30px;
      padding: 47px;
      border-radius: 20px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      div {
        font-size: 20px;
        flex: 1;
        text-align: left;
      }
      .container-title {
        color: rgba(5, 105, 255, 1);
        font-size: 32px;
        flex: 0;
        margin-bottom: 50px;
      }
    }
  }
  //蒙层
  .mantle {
    width: 100%;
    margin: auto;

    .mantle-top {
      width: 100%;
      // margin-bottom: 50px;
      text-align: center;
      .mantle-top-content {
        width: 100%;

        margin-top: 60px;
        margin-bottom: 40px;
        line-height: 30px;
        font-size: 20px;
      }
      .mantle-top_container{
        .mantle-top-img {
        width: 100%;
        display: flex;
        justify-content: center;
        img {
          width: 440px;
          height: 300px;
        }
        img:first-child {
          margin-right: 30px;
        }
      }
      
      }
      
    }
    .mantle-bottom {
      width: 100vw;
      background: rgba(234, 238, 249, 1);
      height: 650px;
      display: flex;
      flex-direction: column;
      margin: auto;
      border-radius: 20px;
      margin-top: 97px;

      .mantle-bottom-title {
        text-align: center;
        font-size: 45px;
        margin-top: 40px;
      }

      .mantle-bottom-container {
        width: 1198px;
            height: 361px;
        margin: auto;
        background: rgba(255, 255, 255, 1);
        box-shadow: 0px 2px 8px 0px rgba(3, 24, 56, 0.3);
        display: flex;
        justify-content: center;

        .mantle-bottom-container-content {
          min-width: 360px;
          position: relative;
          padding: 60px 70px;
          flex: 1;
          div {
            font-size: 20px;
            color: rgba(0, 0, 0, 1);
            text-align: left;
            margin-bottom: 20px;
            text-indent: -0.5em;
          }
          .title {
            font-size: 32px;
            color: rgba(5, 105, 255, 1);
            text-align: center;
            text-indent: 0;
          }
        }
        .mantle-bottom-container-content:first-child::after {
          content: "";
          display: block;
          height: 80%;
          width: 3px;
          position: absolute;
          left: 100%;
          top: 10%;
          background: rgba(5, 105, 255, 1);
        }
      }
    }
  }
}
</style>