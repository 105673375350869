<template>
  <div class="exhibition">
    <div class="top">
      <div class="topTxt">
        <p style="font-weight: 500">展厅中控系统专业解决方案</p>
        <h1>以<span>技术</span>为核心的</h1>
        <h1>交互技术<span>解决方案</span>提供商</h1>
        <p style="font-weight: 400; font-size: 32px; padding-top: 55px">
          拥有自主开发的自控系统及产品，为国内设计师及建造<br />
          方提供最直接的沉浸式互动技术服务
        </p>
      </div>
    </div>
    <div class="advantage">
      <h1>意想意创目前的优势所在</h1>
      <div class="advantageText">
        <p>
          公司作为一家专业从事嵌入式计算机自动化控制的高新企业，<br />
          凭借拥有自主开发的自控系统及产品，为国内设计师及建造方提供最直接的<span>沉浸式互动技术服务</span>，<br />
          是国内少有的<span>以技术为核心</span>的交互技术解决方案提供商，<br />
          协助客户开拓互动技术与设计深度融合的第三代展馆及展览市场，先行获得品牌价值与市场的快速增长！
        </p>
      </div>
      <div class="advantageBlock">
        <div class="advantageBox">
          <span></span>
          <p>
            在行业内深耕<strong>8</strong>年<br />每年产出<strong>1000</strong>个互动方案
          </p>
          <div class="advantageImg">
            <img src="../assets/images/Exhibition/figure.png" alt="" />
          </div>
        </div>
        <div class="advantageBox">
          <span></span>
          <p>前置仓覆盖范围广<br />能够更多的服务全国客户</p>
          <div class="advantageImg">
            <img src="../assets/images/Exhibition/figure.png" alt="" />
          </div>
        </div>
        <div class="advantageBox">
          <span></span>
          <p>一对一售后服务<br />响应及时</p>
          <div class="advantageImg">
            <img src="../assets/images/Exhibition/figure.png" alt="" />
          </div>
        </div>
        <div class="advantageBox">
          <span></span>
          <p style="margin: 13px 50px">
            软件/硬件自主研发<br />依靠强大的Smart体系<br />方案更全，效果更好更稳定
          </p>
          <div class="advantageImg">
            <img src="../assets/images/Exhibition/figure.png" alt="" />
          </div>
        </div>
      </div>
    </div>
    <div class="cenControl">
      <h1>意想意创中控系统</h1>
      <div class="cenControlText">
        <p>
          智能展厅中控系统，可以对展厅内的声光电等各种设备进行<span>集中控制</span>，<br />
          还可以控制门禁，电脑，空调，沙盘灯设备开关。<br />
          是一种能让展厅更加更加现代化的一套<span>软硬件相结合</span>的系统。<br />
          有效克服了展馆跨楼层、空间大、区域多、形状不规则等引起的控制不便、演示不稳定等问题，<br />
          让展厅管理、讲解变得更简单、轻松化，<br />
          一般由中控系统软件，受管理的设备，展厅控制设备组成。
        </p>
      </div>
      <div class="cenControlPgm">
        <div class="cenControlBox">
          <span></span>
          <h2>控制方案</h2>
        </div>
        <p style="min-width: 1200px;display: flex;justify-content: center;">
          本方案旨在为开店预算较低，或追求更容易运维的实景从业者提供一套成本低、施工<br />
          简单、配置更灵活多变的机关方案，充分运用图形化的灵活性，结合主题设计的游戏<br />
          场景，实现声、光、电等震撼效果。
        </p>
        <div class="cenControlImg">
          <img
            v-for="item in cenControlList"
            :key="item.id"
            :src="item.img"
            v-show="item.id == cenControlIndex"
            alt=""
          />
        </div>
        <div class="cenControlCircle">
          <ul>
            <li
              @click="changeImg(item.id)"
              v-for="item in cenControlList"
              :key="item.id"
              :class="item.id == cenControlIndex ? 'active' : ''"
            ></li>
          </ul>
        </div>
        <p style="min-width: 800px;display: flex;justify-content: center;text-align: center;">
          我们的控制方案除了能够实现各个展厅设备的开关外，<br />
          还可以进行编组处理，按照流程进行演示，实现自动化控制。
        </p>
        <div class="cenControlBlock">
          <div
            class="controlBlock"
            v-for="(item, index) in controlBlockList"
            :key="index"
          >
            <h3>{{ item.title }}</h3>
            <span></span>
            <p>{{ item.txtOne }}</p>
            <p>{{ item.txtTwo }}</p>
            <p>{{ item.txtThree }}</p>
          </div>
        </div>
      </div>
      <div class="cenControlCust">
        <div class="cenControlBox">
          <span></span>
          <h2>小程序APP定制</h2>
        </div>
        <p>小程序的界面支持定制，适配更多不同的场景，满足不同的需求。</p>
        <div class="cenControlAPPImg">
          <img src="../assets/images/Exhibition/20220929-163920.jpg" alt="" />
          <img src="../assets/images/Exhibition/20220929-163926.jpg" alt="" />
          <img src="../assets/images/Exhibition/20220929-163932.jpg" alt="" />
        </div>
      </div>
    </div>
    <div class="cenProgramme">
      <h1>意想意创多媒体解决方案</h1>
      <ul>
        <li
          v-for="(item, index) in cenProgrammeList"
          :key="index"
          :class="{ activeSel: cenProgrammeIndex == index }"
          @click="handleTitle(index)"
        >
          {{ item.title }}
        </li>
      </ul>
      <div
        class="cenProgrammeBlock"
        v-for="(item, index) in cenProgrammeList"
        v-show="cenProgrammeIndex == index"
        :key="index + ','"
      >
        <div class="cenProgrammeImg">
          <img :src="item.img" alt="" />
        </div>
        <div class="cenProgrammeBox">
          <span></span>
          <h3>{{ item.title }}</h3>
          <div class="cenProgrammetext">
            <p>{{ item.design }}</p>
            <p>{{ item.tech }}</p>
            <p>{{ item.product }}</p>
            <p>{{ item.model }}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="immersive">
      <h1 style="margin-top: 180px">意想意创沉浸式互动方案</h1>
      <div class="immersiveText">
        <p>
          作为一种体验，它一改传统各类线下项目被动型的体验方式，通过各类<span>个性化的模拟</span>，<br />
          包括：带入式情景、多感官包围、互动型叙事、社交需求实现和自我发现等方式创造出一个魔法圈，<br />
          让参与的消费者能瞬时脱离现实环境，浸入到如同电影、游戏或梦境般的情境中，从而产生持续的临场愉悦感。
        </p>
      </div>
      <div class="immersiveImg">
        <p>依靠Smart模块体系，可以方便落地沉浸式互动方案</p>
      </div>
    </div>
    <div class="serve">
      <h1>意想意创辅助设计服务</h1>
      <div class="serveText">
        <p>
          意想意创在沉浸式互动积累多年的经验，在项目筹划阶段可以给设计师/客户提供辅助设计，<br />
          主要从以下4个方面提升互动方案的落地和实现。
        </p>
      </div>
      <div class="serveBlock">
        <div>
          <h4>设计角度</h4>
          <span></span>
          <p>从设计角度出发，细化设计环节中的机关触发逻辑关系及合理性；</p>
          <p class="number">01</p>
        </div>
        <div>
          <h4>设备角度</h4>
          <span></span>
          <p>
            评估采用什么样的互动方案更符合剧情及环境要求，并根据现场布景的情况卡对最佳的互动实现方案；
          </p>
          <p class="number">02</p>
        </div>
        <div>
          <h4>施工成本角度</h4>
          <span></span>
          <p>权衡综合成本，而不是互动一次性投入成本。</p>
          <p class="number">03</p>
        </div>
        <div>
          <h4>运维成本角度</h4>
          <span></span>
          <p>场控人员投入、维修检修、数据管理与网络安全；</p>
          <p class="number">04</p>
        </div>
      </div>
    </div>
    <div class="now">
      <h1>意想意创正在为您服务</h1>
      <div class="nowBlock">
        <div class="nowContent">
          <div>中央电视台<br />《挑战不可能》</div>
        </div>
        <div class="nowContent">
          <div>上海环球港</div>
        </div>
        <div class="nowContent">
          <div>湖南卫视<br />《明星大侦探》</div>
        </div>
        <div class="nowContent">
          <div>《密室大逃脱》</div>
        </div>
        <div class="nowContent">
          <div>芒果TV栏目组</div>
        </div>
        <div class="nowContent">
          <div>青岛奥帆中心</div>
        </div>
        <div class="nowContent">
          <div>新媒体艺术展</div>
        </div>
        <div class="nowContent">
          <div>中石化国际会议中心<br />沉浸式党建</div>
        </div>
      </div>
    </div>

    <div class="suspension"></div>
    <div class="suspension1"></div>
    <div class="suspension2"></div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      cenControlIndex: 0,
      cenControlList: [
        {
          id: 0,
          img: require("../assets/images/Exhibition/controlImg.jpg"),
        },
        {
          id: 1,
          img: require("../assets/images/Exhibition/controlImg2.jpg"),
        },
      ],
      controlBlockList: [
        {
          title: "可视化操作界面",
          txtOne: "控制设备状态",
          txtTwo: "实时显示",
          txtThree: "",
        },
        {
          title: "图形化修改",
          txtOne: "无需代码",
          txtTwo: "全中文图形化",
          txtThree: "编程界面",
        },
        {
          title: "网络控制",
          txtOne: "项目进度",
          txtTwo: "不受WIFI影响",
          txtThree: "",
        },
        {
          title: "快速落地",
          txtOne: "单条控制效果",
          txtTwo: "5分钟内完成",
          txtThree: "",
        },
      ],
      cenProgrammeIndex: 0,
      cenProgrammeList: [
        {
          title: "枪游戏互动",
          design: "设计：海藻有关的枪击方案/枪击内容/产品外观/UI界面",
          tech: "技术：互动及逻辑程序/穿戴设备/小程序开发",
          product: "产品：设备制作与安装",
          model: "体验方式：游客拿起枪击打目标命中目标加分两人PK或单人体验。",
          img: require("../assets/images/Exhibition/shots.jpg"),
        },
        {
          title: "击鼓互动",
          design: "设计：海藻有关的击鼓方案/产品外观/UI界面",
          tech: "技术：传感器/互动及逻辑程序/小程序开发",
          product: "产品：设备制作与安装",
          model:
            "体验方式：敲鼓的时候海水开始波动，敲打频率越快海藻生长越快，并记录生长高度后进行全国排名。",
          img: require("../assets/images/Exhibition/drum.jpg"),
        },
        {
          title: "点餐互动",
          design: "设计：海藻有关的点餐方案/产品外观/UI界面",
          tech: "技术：传感器/互动及逻辑程序/小程序开发",
          product: "产品：设备制作与安装",
          model:
            "体验方式：游客拿起炒勺，任意选择三类食材后，显示出食材烹饪后对应的卡路里及营养信息。",
          img: require("../assets/images/Exhibition/order.jpg"),
        },
      ],
    };
  },
  methods: {
    handleTitle(index) {
      this.cenProgrammeIndex = index;
    },
    changeImg(index) {
      this.cenControlIndex = index;
    },
  },
};
</script>

<style lang="less" scoped>
.exhibition {
  width: 100%;
  height: 100%;
  min-width: 1200px;
  position: relative;
  overflow: hidden;
  .suspension {
    position: absolute;
    z-index: -1;
    left: -912.38px;
    top: 3727.55px;
    width: 1494.84px;
    height: 1631.48px;
    opacity: 0.3;
    transform: rotate(-360.8931591691106deg);
    background: linear-gradient(
      90deg,
      rgba(72, 124, 234, 1) 0%,
      rgba(255, 255, 255, 1) 100%
    );
    filter: blur(100px);
  }
  .suspension1 {
    position: absolute;
    z-index: -1;
    left: -547.38px;
    top: 5645.38px;
    width: 1505.78px;
    height: 1670.17px;
    opacity: 0.3;
    transform: rotate(22.780703370558427deg);
    background: radial-gradient(
      50% 63.95%,
      rgba(73, 124, 235, 0.5) 0%,
      rgba(73, 124, 235, 0.01) 100%
    );
    filter: blur(100px);
  }
  .suspension2 {
    position: absolute;
    z-index: -1;
    left: 830.85px;
    top: 6101.69px;
    width: 1022px;
    height: 1517.1px;
    opacity: 0.3;
    transform: rotate(22.780703370558427deg);
    background: radial-gradient(
      50% 63.95%,
      rgba(73, 124, 235, 0.5) 0%,
      rgba(73, 124, 235, 0.01) 100%
    );
    filter: blur(100px);
  }
}
* {
  margin: 0px;
  padding: 0px;
}
h1 {
  font-weight: 500;
  font-size: 56px;
  letter-spacing: 2px;
  text-align: center;
  margin-top: 100px;
}
h2 {
  font-weight: 400;
  font-size: 45px;
  letter-spacing: 2px;
  text-align: center;
  margin-top: 80px;
}
h3 {
  font-size: 25px;
  letter-spacing: 1.25px;
  text-align: center;
  font-weight: 400;
}

.top {
  background-image: url(../assets/images/Exhibition/top.jpg);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 100%;
  height: 948px;
  padding: 180px;
  .topTxt {
    p {
      color: white;
      font-size: 30px;
      letter-spacing: 3px;
      padding-bottom: 10px;
      padding-top: 50px;
    }
    h1 {
      font-size: 72px;
      font-weight: 900;
      color: white;
      text-align: left;
      margin-top: 0px;
      letter-spacing: 3px;
      span {
        color: rgba(5, 105, 225, 1);
        text-shadow: 2px 2px 4px #bdb6b6;
      }
    }
  }
}
.advantage {
  max-width: 1300px;
  margin: auto;
  .advantageText {
    text-align: center;
    font-size: 20px;
    color: rgba(107, 107, 107, 1);
    line-height: 30px;
    margin-top: 30px;
    span {
      color: rgba(5, 105, 225, 1);
    }
  }
  .advantageBlock {
    // width: 1230px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    margin: 65px auto;
    span {
      display: block;
      width: 16px;
      height: 16px;
      border: 3px solid rgba(5, 105, 225, 1);
      border-radius: 10px;
      float: left;
      line-height: 10px;
      margin: 60px 30px 60px 40px;
    }
    .advantageBox {
      box-shadow: 0px 0px 10px 0px #bdb6b6;
      border-radius: 15px;
      width: 45%;
      height: 135px;
      font-size: 20px;
      margin: 15px;
      overflow: hidden;
      background: linear-gradient(
        90deg,
        rgba(255, 255, 255, 0) 0%,
        rgba(242, 247, 255, 1) 100%
      );
      p {
        margin: 30px 50px;
        line-height: 35px;
        font-weight: 400;
      }
      .advantageImg {
        width: 300px;
        float: right;
        margin-top: -190px;
        margin-right: -60px;
        transform: rotate(5deg);
        img {
          width: 100%;
        }
      }
    }
  }
}
.cenControl {
  .cenControlText {
    text-align: center;
    font-size: 20px;
    font-weight: 400;
    color: rgba(107, 107, 107, 1);
    line-height: 30px;
    margin-top: 30px;
    span {
      color: rgba(5, 105, 225, 1);
    }
  }
  .cenControlPgm {
    margin: auto;
    .cenControlBox {
      margin: 0 auto;
      width: 235px;
      span {
        display: block;
        width: 24px;
        height: 24px;
        float: left;
        border: 4px solid rgba(5, 105, 225, 1);
        border-radius: 15px;
        margin-top: 24px;
      }
    }
    p {
      width: 45%;
      text-align: left;
      font-size: 21px;
      font-weight: 400;
      color: rgba(78, 76, 76, 1);
      line-height: 28px;
      margin: 50px auto;
      letter-spacing: 2px;
    }
    .cenControlImg {
      display: flex;
      justify-content: center;
      margin: auto;
      img {
        width: 100%;
        max-width: 1198px;
        height: 587px;
      }
    }
    .cenControlCircle {
      width: 100%;
      height: 20px;
      ul {
        margin: 0 50px;
        height: 100%;
        text-align: center;
        li {
          display: inline-block;
          width: 12px;
          height: 12px;
          margin: 0 5px;
          border-radius: 12px;
          border: 2px solid rgba(128, 128, 128, 1);
          cursor: pointer;
        }
      }
    }
    .active {
      background-color: rgba(5, 105, 225, 1);
      border: 2px solid rgba(5, 105, 225, 1) !important;
    }
    .cenControlBlock {
      width: 1200px;
      display: flex;
      justify-content: space-around;
      margin: 40px auto 0;
      .controlBlock {
        border: 2px solid black;
        width: 272px;
        height: 244px;
        border-radius: 15px;
        padding: 40px 30px;
        span {
          display: block;
          width: 70px;
          height: 6px;
          background-color: black;
          margin: 24px auto 36px;
        }
        p {
          text-align: center;
          width: 100%;
          font-size: 21px;
          font-weight: 400;
          margin: 0;
          line-height: 25px;
          letter-spacing: 1px;
          color: rgba(78, 76, 76, 1);
        }
      }
    }
  }
  .cenControlCust {
    max-width: 1300px;
    margin: auto;
    .cenControlBox {
      margin: 0 auto;
      width: 360px;
      span {
        display: block;
        width: 24px;
        height: 24px;
        float: left;
        border: 4px solid rgba(5, 105, 225, 1);
        border-radius: 15px;
        margin-top: 23px;
      }
    }
    p {
      text-align: center;
      font-size: 21px;
      font-weight: 400;
      color: rgba(78, 76, 76, 1);
      line-height: 28px;
      margin: 20px auto;
      letter-spacing: 2px;
    }
    .cenControlAPPImg {
      display: flex;
      justify-content: center;
      img {
        max-width: 31%;
        margin-right:39px;
        object-fit: cover;
        height: 740px;
      }
      :last-child{
          margin-right: 0;
        }
    }
  }
}
.cenProgramme {
  // height: 640px;
  ul {
    width: 595px;
    margin: 70px auto;
  }
  ul li {
    list-style: none;
    float: left;
    border: 1px solid rgba(107, 107, 107, 1);
    padding: 5px 21px 7px;
    width: 165px;
    text-align: center;
    font-size: 24px;
    font-weight: 400;
    border-radius: 30px;
    margin: 0px 15px;
    cursor: pointer;
    color: rgba(107, 107, 107, 1);
  }
  .activeSel {
    background: rgba(5, 105, 225, 1);
    border: none;
    color: white;
  }
  .cenProgrammeBlock {
    display: flex;
    justify-content: center;
    width: 100%;
    padding-top: 60px;
    margin: 0 auto;
    padding-left: 60px;
    .cenProgrammeImg {
      width: 684px;
      margin-right: 40px;
      img {
        width: 100%;
        border-radius: 15px;
      }
    }
    .cenProgrammeBox {
      padding: 40px;
      letter-spacing: 2px;
      width: 520px;
      span {
        display: block;
        width: 24px;
        height: 24px;
        float: left;
        border: 4px solid rgba(5, 105, 225, 1);
        border-radius: 15px;
        margin-top: 20px;
      }
      h3 {
        font-size: 38px;
        font-weight: 400;
        text-align: left;
        margin-left: 37px;
        font-weight: 500;
        padding-bottom: 10px;
      }
      .cenProgrammetext {
        font-size: 21px;
        font-weight: 400;
        line-height: 30px;
        p {
          margin-top: 20px;
        }
      }
    }
  }
}
.immersive {
  max-width: 1300px;
  margin: auto;
  .immersiveText {
    text-align: center;
    font-size: 20px;
    color: rgba(107, 107, 107, 1);
    line-height: 30px;
    margin-top: 30px;
    span {
      color: rgba(5, 105, 225, 1);
    }
  }
  .immersiveImg {
    background-image: url(../assets/images/Exhibition/immersive.jpg);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    height: 500px;
    margin: 50px auto;
    border-radius: 15px;
    position: relative;
    p {
      color: white;
      font-size: 30px;
      font-weight: 500;
      position: absolute;
      right: 50px;
      bottom: 30px;
      letter-spacing: 2px;
    }
  }
}
.serve {
  width: 100%;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  .serveText {
    text-align: center;
    font-size: 20px;
    font-weight: 400;
    color: rgba(107, 107, 107, 1);
    line-height: 30px;
    margin-top: 30px;
    span {
      color: rgba(5, 105, 225, 1);
    }
  }
  .serveBlock {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 60px;
    max-width: 1300px;
    div {
      width: 40%;
      max-width: 591px;
      min-width: 560px;
      height: 310px;
      background-color: #e4e4e4;
      border-radius: 10px;
      padding: 40px 50px;
      margin: 10px 5px;
      position: relative;
      h4 {
        font-size: 38px;
        font-weight: 400;
      }
      span {
        display: block;
        width: 34px;
        height: 6px;
        background: white;
        margin: 10px 0px 18px;
      }
      p {
        font-size: 21px;
        font-weight: 400;
      }
      .number {
        font-size: 72px;
        font-weight: 400;
        letter-spacing: 2px;
        color: white;
        text-align: right;
        position: absolute;
        right: 30px;
        bottom: 10px;
      }
    }
  }
}
.now {
  .nowBlock {
    width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    margin-top: 60px;
    .nowContent {
      border: 1px solid;
      text-align: center;
      font-size: 21px;
      font-weight: 400;
      margin-top: 15px;
      width: 282px;
      height: 105px;
      line-height: 95px;
      letter-spacing: 2px;
      div {
        display: inline-block;
        vertical-align: middle;
        line-height: 30px;
      }
    }
  }
}
</style>