<template>
  <div class="footer">
    <div class="footer-box">
      <div class="footer-bg">
        <div class="footer-content">
          <div class="corporate">
            <h3>公司业务</h3>
            <p class="actv"  v-for="(item, index) in Corporate" :key="index">{{ item }}</p>
          </div>
          <div class="shop">
            <h3>淘宝店铺</h3>
            <p @click="jump('shop',index)" class="actv"  v-for="(item, index) in shop" :key="index" >{{ item }}</p>
          </div>
          <div class="link">
            <h3>友情链接</h3>
            <p @click="jump('link',index)" class="actv"  v-for="(item, index) in link" :key="index">{{ item }}</p>
          </div>
          <div class="about">
            <h3>关于我们</h3>
            <p @click="jump('about',index)" class="actv"  v-for="(item, index) in about" :key="index">{{ item }}</p>
            <div class="image-vx">
              <div>
                <img src="../assets/gzhwx.jpg" alt="" />
                <p>公众微信号</p>
              </div>
              <div>
                <img src="../assets/mskf.webp" alt="" />
                <p>密室客服微信</p>
              </div>
              <div>
                <img src="../assets/mckf.webp" alt="" />
                <p>美陈客服微信</p>
              </div>
              <div class="potGA">

                <a target="_blank"  href="http://beian.miit.gov.cn">鲁ICP备15002068号-2</a>
                <div>版权所有：青岛意想意创技术发展有限公司</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      Corporate: [
        "实景业务",
        "展厅业务",
        "互动陈美业务",
        "核心工具",
        "其他业务",
      ],
      shop: ["意想意创企业店铺", "意想意创密室机关店", "意想意创互动产品"],
      link: ["意想意创论坛", "图形化网页版", "意想意创说明书", "资料下载"],
      about: [
        "官方网址:www.yxyc.top",
        "公司邮箱:yxyc@yxyc.top",
        "咨询电话:15020089598",
      ],
    };
  },
  methods:{
    jump(key,index){
      if(key=='about'&& index==0) window.open('https://www.yxyc.top/')
      if(key=='link'&& index==0) window.open('www.yxyc.top')
      if(key=='link'&& index==1) window.open('https://remote.yxyc.top/')
      if(key=='link'&& index==2) window.open('https://book.yxyc.top/')
      if(key=='link'&& index==3) window.open('https://www.yxyc.top/#/Download')
      // if(key=='link'&& index==3) window.open('http://localhost:8080/#/Download')
      if(key == 'shop' && index == 0) window.open('https://yxyc.taobao.com/?spm=2013.1.0.0.c13c1f4eRHLLok')
      if(key == 'shop' && index == 1) window.open('https://shop433109681.taobao.com/?spm=a230r.7195193.1997079397.15.68ee27f9jSDBnx')
      if(key == 'shop' && index == 2) window.open('https://shop192433962.taobao.com/?spm=a230r.7195193.1997079397.30.7b60176ccLFuS9')
      
    }
  }
};
</script>

<style lang="less" scoped>
.footer {
  width: 100%;
  color: #fff;
  height: 660px;
  overflow: hidden;
  min-width: 1200px;
.actv{
  cursor: pointer;
}
  .footer-box {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 660px;
    position: relative;
    overflow: hidden;

    .footer-bg {
      position: absolute;
      top: 65%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 140%;
      height: 660px;
      bottom: 200px;
      border-radius: 50%;
      background: #477cea;
    //   z-index: -1;
      .footer-content {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: flex;
        h3 {
          font-size: 16px;
          margin-bottom: 30px;
        }
        p {
          margin-bottom: 20px;
          margin-right: 60px;
        }
        p:last-child{
            margin-right: 0;
        }
        p:first-child{
            margin-left: 30px;
        }
        .image-vx{
            display: flex;
            text-align: center;
            .potGA{
              position: absolute;
                bottom: -20px;
                display: flex;
                font-size: 14px;
                left: 20%;
                div{
                  margin-left: 20px;
                }
                a{
                  color: #fff;
                  text-decoration: none;
                }
                a:hover{
                  
                  transition: all .3s;
                }
            }
            img{
                width: 100px;
                height: 100px;
                margin-right: 20px;
            }
            p{
               width: 100px;
            }
        }
      }
    }
  }
}
</style>