import { render, staticRenderFns } from "./DisplayBusiness.vue?vue&type=template&id=63c0bff6&scoped=true"
import script from "./DisplayBusiness.vue?vue&type=script&lang=js"
export * from "./DisplayBusiness.vue?vue&type=script&lang=js"
import style0 from "./DisplayBusiness.vue?vue&type=style&index=0&id=63c0bff6&prod&lang=less&scoped=true"
import style1 from "./DisplayBusiness.vue?vue&type=style&index=1&id=63c0bff6&prod&lang=less"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "63c0bff6",
  null
  
)

export default component.exports