<template>
  <div class="realistic">
    <!-- banner -->
    <div class="banner">
      <div class="banner-content">
        <div class="ft72 ftw">
          <div><span class="color006">“主题”</span>快速落地</div>
          <div>体验<span class="color006">“全面升级”</span></div>
        </div>
        <div class="banner-conrainer-text ft32">
          <div>基于打造沉浸式产业全产业链技术工具</div>
          <div>使用自主研发的配置软件，针对不同的应用场景提供专</div>
          <div>业的、系统的、沉浸式互动解决方案</div>
        </div>
      </div>
    </div>

    <Solve :solve="solve"></Solve>
    <div class="gear mgat">
      <h2 class="ft56">机关点解决方案</h2>
      <p class="ft18 color7d">
        本方案旨在为开店预算较低，或追求更容易运维的实景从业者提供一套成本低、施工简单、配置更灵活多变的机关方案，充分
        <br />运用图形化的灵活性，结合主题设计的游戏场景，实现声、光、电等震撼效果。
      </p>
      <div class="gear-img">
        <div style="width: 45%; height: 414px; display: flex; align-items: center; margin-right: 67px">
          <img
            style="display: block; margin: 0 auto"
            :class="{ isActive: activeImg1 == true }"
            src="../assets/images/Realistic/舞台灯光现场.jpg"
            alt=""
            @mouseenter="activeImg1 = true"
            @mouseleave="activeImg1 = false"
          />
        </div>
        <div style="width: 45%; height: 414px; display: flex; align-items: center">
          <img
            style="display: block; margin: 0 auto"
            :class="{ isActive: activeImg2 == true }"
            src="../assets/images/Realistic/科幻太空通道.jpg"
            alt=""
            @mouseenter="activeImg2 = true"
            @mouseleave="activeImg2 = false"
          />
        </div>
      </div>
    </div>

    <Solve :solve="solve2"></Solve>

    <div class="central-control">
      <div class="central-content mgat">
        <h2 class="ft56">中控解决方案</h2>
        <div class="central-nav">
          <i class="el-icon-back" @click="changeBtn('actv', 'centralNav', -1)"></i>
          <div
            class="color7d"
            :class="{ activacolor: actv == index }"
            v-for="(item, index) in centralNav"
            :key="index"
            @click="handel(index)"
          >
            {{ item }}
          </div>
          <i class="el-icon-right" @click="changeBtn('actv', 'centralNav', 1)"></i>
        </div>

        <!-- 选项卡 -->
        <div class="tablecard" v-show="actv === 0">
          <img src="../assets/images/Realistic/SmartX3.png" alt="" />
          <div class="card-container">
            <div class="cardtitle">
              简单，易操作<br />
              教你一招玩转机关
            </div>
            <div class="care-text care-text-first-child">
              ◦ 意想意创融合了阿里云小程序的优势，设备联网，手机端与电脑端实现多端控制，使机关控制更加简单、易操作。
            </div>
            <div class="care-text">
              ◦
              为密室从业者快速搭建一套兼备监控、远程控制、远程调试、分享、加载等功能的专业机关方案，实现多主题的配置数据云存储，及离线运行，为店铺断网运行、数据安全保驾护航。
            </div>
          </div>
        </div>
        <div class="tablecard" v-show="actv === 1">
          <img src="../assets/images/Realistic/lxzkfa.jpg" alt="" />
          <div class="card-container">
            <div class="cardtitle">无需联网</div>
            <div class="care-text care-text-first-child">
              ◦ 本方案是基于Smart
              X3模块，在离线中控台上实现不联网，即可操作X3的全部运营功能：启动、复位、跳关、快捷效果、提示音、速控等。
            </div>
            <div class="cardtitle">远离误触发</div>
            <div class="care-text">
              ◦ 用于Smart
              X3系列模块搭建的中控系统的离线控制，延续传统的按键控制习惯，但又创新优化了操作界面，屏幕显示同步，解决多按钮误触发的场控历史性难题。
            </div>
          </div>
        </div>

        <div class="tablecard" v-show="actv === 2">
          <img src="../assets/images/Realistic/ktxyfa.png" alt="" />
          <div class="card-container">
            <div class="cardtitle">
              更多功能<br />
              可满足不同需求
            </div>
            <div class="care-text care-text-first-child">
              ◦ 开放离线中控台与Smart
              X3模块通讯所使用的“控台协议”，可用于搭建开发客户专属的中控系统，控制程序，或其他场景下的应用。
            </div>
            <div class="care-text">
              ◦ 可进行二次开发，开放式接口给予有一定能力的技术开发者创造更多满足自我需求的功能系统。
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 黑科技选项卡 -->
    <div class="technology-card">
      <div class="technology-card-container">
        <h2 class="ft56">9大黑科技玩转中控</h2>
        <div
          class="technology-card-list"
          :class="{ CardListActive: cardid == index }"
          v-for="(item, index) in technologylist"
          :key="index"
          @click="handelCardList(index)"
        >
          {{ item }}
        </div>
      </div>
      <div
        class="technology-card-content"
        v-for="(item, index) in technologyurl"
        :key="index"
        v-show="index === cardid"
      >
        <img :src="item" alt="" />
      </div>
    </div>

    <Solve :solve="solve3"></Solve>
    <!-- 大型机械机关 -->
    <div class="gear mgat">
      <h2 class="ft56">大型机械机关解决方案</h2>
      <p class="ft18 color7d">
        为满足主题中大型场景的声、光、电、火、雷等更多震撼的视觉效果冲击，给玩家带来更强烈的沉浸式代入感，意想意创携手沈阳仓MOJO联合打造，利用气动系统等上百种的控制方式，结合Smart模块灵活控制，搭配工业级标准硬件设备，渲染营造恐怖、震撼的沉浸式实景氛围，出乎玩家意料之外，成为主题中不可或缺的亮点，赢得更多玩家好评。
      </p>
      <div class="gear-img">
        <img
          style="width: 45%; height: 380px; margin-right: 17px"
          src="../assets/images/Realistic/礼物盒子.gif"
          alt=""
        />
        <img style="width: 45%; height: 380px" src="../assets/images/Realistic/月食开门.gif" alt="" />
      </div>
    </div>

    <Solve :solve="solve4"></Solve>
    <!-- <div class="gear mgat">
      <h2 class="ft56">Yi-net方案</h2>
      <p class="ft18 color7d">
        Yi-net方案支持更多不同类型的主题，无需考虑负载端口布局，充分发挥图形化配置的强大优势，搭载小程序中控控制，解决施工布线时间长、等待图纸时间长、增加效果步骤复杂、寻线费时费力等行业痛点，在更改机关效果、施工安装、运维等实景建造与运营的全过程上真正实现简单易操作，轻松掌握！
      </p>
      <div class="gear-img">
        <img
          class="only-img"
          src="../assets/images/Realistic/yinet.jpg"
          alt=""
          title="关于使用场景与技术方案详情，请咨询您的专业工程师团队"
        />
      </div>
    </div> -->

   

    <div class="central-control">
      <div class="central-content mgat">
        <h2 class="ft56">机关方案一站式服务</h2>
        <div class="central-nav">
          <i class="el-icon-back" @click="changeBtn('actv1', 'Agency', -1)"></i>
          <div
            class="color7d"
            :class="{ activacolor: actv1 == index }"
            v-for="(item, index) in Agency"
            :key="index"
            @click="handel1(index)"
          >
            {{ item }}
          </div>
          <i class="el-icon-right" @click="changeBtn('actv1', 'Agency', 1)"></i>
        </div>

        <!-- 选项卡 -->
        <div class="tablecard" v-show="actv1 === index" v-for="(item, index) in AgencyCard" :key="index">
          <div class="card-container">
            <div class="cardtitle">
              {{ item.title }} <br />
              {{ item.title1 }}
            </div>
            <div class="care-text care-text-first-child">{{ item.text1 }}</div>
            <div class="care-text">{{ item.text2 }}</div>
          </div>

          <img class="img-right" :src="item.url" alt="" />
        </div>

        <div class="customer-service">
          <h2>更多方案•请联系我们咨询哦</h2>

          <el-popover placement="top" trigger="click" popper-class="mask">
            <div class="mask">
              <div class="mask-left">
                <div class="mask-left-title">
                  <div>在线交谈</div>
                  <div>扫描下方二维码进行产品咨询</div>
                </div>
                <div class="mask-bg"></div>
              </div>
              <div class="mask-right">
                <div class="mask-right-title">
                  <div>客服电话</div>
                  <div>拨打电话立即咨询</div>
                </div>
                <div class="mask-phone">15054896836</div>
              </div>
            </div>
            <el-button class="customer-service-btn" type="primary" round slot="reference">立即联系</el-button>
          </el-popover>
        </div>
      </div>
    </div>

    <Solve :solve="solve5"></Solve>
    
    <div class="design">
      <div class="design-content">
        <h2>自己不会设计?</h2>
        <div class="design-content-text">
          ◦ 专业的角度提供关键性的建议与优化意见，以辅助设计师顺利完成新主题机关流程的设计。
        </div>

        <el-popover placement="top" trigger="click" popper-class="mask">
          <div class="mask">
            <div class="mask-left">
              <div class="mask-left-title">
                <div>在线交谈</div>
                <div>扫描下方二维码进行产品咨询</div>
              </div>
              <div class="mask-bg"></div>
            </div>
            <div class="mask-right">
              <div class="mask-right-title">
                <div>客服电话</div>
                <div>拨打电话立即咨询</div>
              </div>
              <div class="mask-phone">15054896836</div>
            </div>
          </div>
          <el-button class="design-content-btn" type="primary" round slot="reference"
            >联系客服 <i class="el-icon-arrow-right el-icon-right"></i
          ></el-button>
        </el-popover>
      </div>
      <div>
        <img src="../assets/images/Realistic/fzsjch.jpg" alt="" />
      </div>
    </div>

    <div class="subject">
      <div>
        <img src="../assets/images/Realistic/zzt.png" alt="" />
      </div>
      <div class="subject-content">
        <h2>找主题就来“<span>找主题</span>”</h2>
        <div>
          ◦
          找主题平台是意想意创为实景商家和设计师搭建的连接桥梁，整合了全国很多优质的原创主题，上架主题把关严格，保证主题原创版权，签订保密协议，目前平台主题持续更新中，为商家输送优质主题，也为设计师提供商家需求。
        </div>
        <div>
          ◦ 无论您是设计师还是施工方或商家，欢迎各位一起洽谈平台合作，让更多的密室从业者们受益，维持行业的良性大循环。
        </div>

        <el-button class="subject-content-btn" type="primary" round @click="handelJump"
          >跳转详情 <i class="el-icon-arrow-right el-icon-right"></i
        ></el-button>
      </div>
    </div>

    <div class="suspension"></div>
    <div class="suspension1"></div>
    <div class="suspension2"></div>
    <div class="suspension3"></div>
  </div>
</template>

<script>
import Solve from "../components/solve.vue";
export default {
  data() {
    return {
      tabPosition: "left",
      centralNav: ["Smart X3中控方案", "离线中控方案", " 控台协议方案"],
      Agency: ["主题解决", "方案设计", " 安装指导"],
      actv: 0,
      actv1: 0,
      cardid: 0,
      activeImg1: false,
      activeImg2: false,
      solve: [
        { title: "◦ 预算少", text: "小主题,简单机关,实现0成本机关复用。" },
        { title: "◦ 担心施工困难 ", text: "只需一根电源线通电即用。" },
        {
          title: "◦ 售后难以查出问题所在",
          text: "专属工具箱30分钟快速查找问题所在，轻松定位，快速替换，不影响正常营业。",
        },
        {
          title: "◦ 后期改玩法麻烦",
          text: "随时自由调整机关效果，图形化配置轻松掌握，更加满足玩家体验。",
        },
      ],
      solve2: [
        {
          title: "◦ 场控误触发，客户体验差",
          text: "中控解决方案实现手机端与电脑端双端控制，手机端一屏掌控密室所有主题，一键启动、一键跳关和复位，真正实现场控控制简单极致化。",
        },
        {
          title: "◦ 调整机关顺序、机关调试等操作复杂 ",
          text: "只需一根电源线通电即用。",
        },
        {
          title: "◦ 电脑更换，人员离职等导致机关配置易丢失",
          text: "配置数据“云存储”，永不丢失，同一主题可保存多种配置，更换主题也不怕，拆下重新加载配置即可。",
        },
        {
          title: "◦ 突然断网，机关无法运行，影响营业",
          text: "不受网络限制，运行更自由，搭配离线中控台，不必联网运行设备，店铺数据更安全。",
        },
      ],
      solve3: [
        {
          title: "◦ 制作成本高",
          text: "结合模块的强大图形化功能，低成本的模块转化实现复杂效果，优势明显。",
        },
        {
          title: "◦ 问题查找困难，售后成本高 ",
          text: "模块替换简单，0成本复用，配置检查简单。",
        },
        {
          title: "◦ 效果实现单一化 ，修改困难",
          text: "图形化配置的灵活性特点，搭配工业级标准硬件设备，在营造声、光、电、火、雷等机关效果上，渲染恐怖、震撼的沉浸式实景氛围。",
        },
        {
          title: "◦ 修改困难",
          text: "图形化配置的灵活性特点，可搭配工业级硬件设备。",
        },
      ],
      solve5: [
        {
          title: "◦ 市面成熟的一站式服务少，筛选时间长",
          text: "意想意创从事沉浸式行业近10年，经验丰富，专业工程师服务团队，服务近6000家实景（密室、剧本杀），互动技术方案30000+。",
        },
        {
          title: "◦ 品牌建造方施工成本高 ",
          text: "优选全国22家前置仓，具备Smart模块大咖能力，落地城市就近安排，大大减少差旅成本，及后期运维方便上门，让您的店铺运维无忧。",
        },
      ],
      solve4: [
        {
          title: "◦ 施工布线时间长",
          text: "施工布线无需等待，不依靠机关商/机关师图纸就可以进行准确布线。",
        },
        {
          title: "◦ 增加效果步骤复杂 ",
          text: "增加线路只需要本房间内处理即可，不需要到中控，不需要确认是否可以增加该效果。",
        },
        {
          title: "◦ 测试效果麻烦",
          text: "测试机关效果更方便，省去数电箱输出口动作。",
        },
        {
          title: "◦ 售后寻线费时费力 ",
          text: "都在同一房间内，数量少，避免出错。",
        },
      ],

      technologylist: [
        "01 场控简单极致化",
        "02 地图界面，了解玩家进程",
        "03 一键快速完成机关检测",
        "04 机关顺序自由调整",
        "05 断网不“慌”机关照常跑",
        "06 “云端”让机关充满更多可能",
        "07 分级管理,设备更安全",
        "08 手机端轻松配置",
        "09 输入、输出口无限拓展",
      ],
      technologyurl: [
        require("../assets/images/Realistic/hkj01.png"),
        require("../assets/images/Realistic/hkj02.png"),
        require("../assets/images/Realistic/hkj03.png"),
        require("../assets/images/Realistic/hkj04.png"),
        require("../assets/images/Realistic/hkj05.png"),
        require("../assets/images/Realistic/hkj06.png"),
        require("../assets/images/Realistic/hkj07.png"),
        require("../assets/images/Realistic/hkj08.png"),
        require("../assets/images/Realistic/hkj09.png"),
      ],
      AgencyCard: [
        {
          url: require("../assets/images/Realistic/sjfa.jpg"),
          title: "100%还原主图",
          text1:
            "◦ 设计师的原创主题在交付我们机关商来出机关方案，专业工程师将对主题素材资料细分，结合素材对文案进行解读。",
          text2: "◦ 并与提供的机关流程详细比照，进一步与设计师进行细节核对与确认，100%还原设计师之所构想的机关效果。",
        },
        {
          url: require("../assets/images/Realistic/fasj.jpg"),
          title: "专业工程师",
          title1: "为你设计最佳方案",
          text1:
            "◦ 专业工程师在解读完报最终方案时，根据应用的游戏场景，房间格局，灯锁数量及控制方式，结合客户预算，进行最优方案的设计。",
        },
        {
          url: require("../assets/images/Realistic/azzd.jpg"),
          title: "工程师远程指导",
          title1: "售后无忧",
          text1: "◦ 订单发货后客服全程跟踪主题机关的落地进度，用户可享受价值2000元的四次售后安装指导。",
          text2:
            "◦ 工程师远程指导安装，客户如有需要现场指导，可提供有偿的FAE上门指导服务，也可推荐全国22家前置仓就近进行有偿安装或施工服务。",
        },
      ],
    };
  },
  components: {
    Solve,
  },
  methods: {
    handel(index) {
      this.actv = index;
    },
    handel1(index) {
      this.actv1 = index;
    },
    handelCardList(index) {
      this.cardid = index;
    },
    handelJump() {
      window.open("https://zhuti.yxyc.top/", "_blank");
    },
    changeBtn(key, arr, num) {
      if (this[key] + num < 0) {
        this[key] = this[arr].length - 1;
        return;
      }
      if (this[key] + num > this[arr].length - 1) {
        this[key] = 0;
        return;
      }

      this[key] += num;
      return;
    },
  },
};
</script>

<style lang="less" scoped>
.realistic {
  min-width: 1200px;
  position: relative;
  overflow: hidden;
  .suspension {
    position: absolute;
    z-index: -1;
    left: 255.21px;
    top: 928.9px;
    width: 1533.78px;
    height: 1491.12px;
    opacity: 0.2;
    transform: rotate(-358.18616964486176deg);
    background: radial-gradient(50% 50%, rgba(72, 124, 234, 0.5) 0%, rgba(255, 255, 255, 0.5) 100%);
    filter: blur(100px);
  }

  .suspension1 {
    position: absolute;
    z-index: -1;
    left: -657.85px;
    top: 3915.78px;
    width: 1200px;
    height: 850px;
    opacity: 0.2;
    transform: rotate(-358.18616964486176deg);
    background: radial-gradient(50% 50%, rgba(72, 124, 234, 1) 0%, rgba(255, 255, 255, 1) 100%);
    filter: blur(100px);
  }
  .suspension2 {
    position: absolute;
    z-index: -1;
    left: 1129.09px;
    top: 4189.28px;
    width: 980px;
    height: 921.56px;
    opacity: 0.2;
    transform: rotate(-358.18616964486176deg);
    background: radial-gradient(50% 50%, rgba(72, 124, 234, 0.7) 0%, rgba(255, 255, 255, 0.7) 100%);
    filter: blur(100px);
  }
  .suspension2 {
    position: absolute;
    z-index: -1;
    left: 149.21px;
    top: 6073.9px;
    width: 1533.78px;
    height: 1491.12px;
    opacity: 0.2;
    transform: rotate(-358.18616964486176deg);
    background: radial-gradient(50% 50%, rgba(72, 124, 234, 0.5) 0%, rgba(255, 255, 255, 0.5) 100%);
    filter: blur(100px);
  }
  .banner {
    width: 100%;
    height: 941px;
    background: url(../assets/images/Realistic/banner.jpg) no-repeat center;
    background-size: cover;
    position: relative;
    .banner-content {
      position: absolute;
      top: 170px;
      left: 300px;
      .banner-conrainer-text {
        color: rgba(56, 56, 56, 1);
        margin-top: 60px;
      }
    }
  }

  .gear {
    align-items: center;
    width: 90%;
    margin: auto;
    max-width: 1300px;
    h2 {
      margin: 50px 0;
      text-align: center;
    }
    p {
      text-align: center;
      width: 90%;
      margin: auto;
    }
    .gear-img {
      display: flex;
      justify-content: center;
      margin-top: 50px;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 20px;
      }
      .isActive {
        transform: scale(1.1);
        transition: 0.5s;
      }
      .only-img {
        width: 100%;
        height: 600px;
        object-fit: cover;
      }
    }
  }

  .central-control {
    width: 100%;
    background: #e9eff8;
    min-width: 1200px;

    .central-content {
      width: 90%;
      display: flex;
      flex-direction: column;
      align-items: center;
      max-width: 1300px;
      i {
        cursor: pointer;
      }
      h2 {
        margin-top: 50px;
      }
      .central-nav {
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        background: #fff;
        width: 90%;
        height: 100px;
        font-size: 20px;
        position: relative;
        box-shadow: 0 0 5px #d7d7d7;
        border-radius: 20px;
        margin-top: 50px;
        margin-bottom: 90px;
        .activacolor {
          color: #000;
          position: relative;
          text-align: center;
        }
        .activacolor::after {
          content: " ";
          position: absolute;
          left: 15%;
          bottom: -36px;
          width: 50%;
          height: 1px;
          border: 5px solid transparent;
          border-bottom: 5px solid #006aff;
        }
        div {
          cursor: pointer;
        }
        i {
          width: 30px;
          line-height: 70px;
          background: #b4d4ff;
          color: #fff;
        }
        i:first-child {
          border-radius: 10px 0 0 10px;
          text-align: right;
          position: absolute;
          left: -30px;
        }
        i:last-child {
          border-radius: 0 10px 10px 0;
          position: absolute;
          right: -30px;
        }
      }
      //选项卡
      .tablecard {
        display: flex;
        align-items: center;
        margin-bottom: 100px;
        img {
          height: 460px;
          object-fit: cover;
          border-radius: 30px;
          margin-right: 60px;
        }
        .img-right {
          margin-right: 0;
          margin-left: 60px;
        }
        .card-container {
          .cardtitle {
            font-size: 35px;
            font-weight: bold;
            margin-bottom: 30px;
          }
          .care-text {
            font-size: 20px;
            text-indent: -0.5em;
          }
          .care-text-first-child {
            margin-bottom: 20px;
          }
        }
      }
    }
  }
  .technology-card {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 90%;
    margin: auto;
    height: 800px;
    .technology-card-container {
      margin-right: 40px;

      h2 {
        width: 290px;
        margin-bottom: 50px;
      }
      .technology-card-list {
        font-size: 20px;
        margin-bottom: 20px;
        color: #7d7d7d;
      }
      .CardListActive {
        color: #000;
      }
      .technology-card-list:hover {
        color: #000;
        cursor: pointer;
      }
    }

    .technology-card-content {
      width: 900px;
      height: 660px;
      position: relative;
      min-width: 900px;
      &::after {
        content: "";
        background: url(../assets/images/Realistic/背景底图.png);
        opacity: 0.5;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        margin: auto;
        position: absolute;
        z-index: -1;
      }
      img {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        border-radius: 30px;
        object-fit: cover;
      }
    }
  }
  .customer-service {
    font-size: 20px;
    width: 500px;
    margin-bottom: 50px;
    text-align: center;

    .customer-service-btn {
      margin-top: 14px;
      width: 100%;
    }
  }
  .design {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    min-width: 1200px;
    .design-content {
      width: 350px;
      h2 {
        font-size: 50px;
      }
      .design-content-text {
        margin: 40px 0;
        font-size: 18px;
        text-align: left;
        line-height: 26px;
      }
      .design-content-btn {
        width: 220px;
      }
    }

    img {
      width: 700px;
      height: 600px;
    }
  }
  .subject {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    background: #e9eff8;
    min-width: 1200px;

    .subject-content {
      h2 {
        font-size: 50px;
        margin-bottom: 50px;
        span {
          color: #477cea;
        }
      }
      div {
        width: 600px;
        margin-bottom: 20px;
        text-align: left;
      }
    }
    img {
      height: 600px;
      object-fit: cover;
      margin-right: 100px;
    }
  }
}
</style>
<style lang="less">
.mask {
  width: 500px;
  display: flex;
  justify-content: space-around;
  border-radius: 30px;
  .mask-left {
    display: flex;
    flex-direction: column;
    text-align: left;
    margin-left: 30px;
    .mask-left-title {
      position: relative;

      margin-bottom: 20px;
    }
    .mask-left-title > div:first-child {
      font-size: 24px;
    }
    .mask-left-title::after {
      content: "";
      position: absolute;
      width: 100%;
      height: 2px;
      background: rgba(204, 204, 204, 1);
      bottom: -13px;
    }

    .mask-bg {
      background: url(../assets/images/PageLayout/二维码.png) no-repeat;
      background-size: cover;
      width: 160px;
      height: 160px;
      margin: auto;
    }
  }
  .mask-left::after {
    content: "";
    position: absolute;
    width: 2px;
    height: 80%;
    background: #cccccc;
    right: 50%;
  }
  .mask-right {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: auto;
    text-align: left;
    height: 100%;

    .mask-right-title {
      position: relative;
      margin-bottom: 20px;
    }
    .mask-right-title > div:first-child {
      font-size: 24px;
    }
    .mask-right-title::after {
      content: "";
      position: absolute;
      width: 100%;
      height: 2px;
      background: rgba(204, 204, 204, 1);
      bottom: -13px;
    }
    .mask-phone {
      width: 160px;
      height: 160px;
      color: rgba(5, 105, 255, 1);
      font-size: 24px;
    }
  }
}
</style>
